.page-container {
  width: 100%;
  height: calc(100% - 49px);
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
}

.tempalte-list-main-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-container {
  width: 100%;
  height: 75px;
  padding-right: 16px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-text-box{
  width: auto;
  height: 44px;
  display: flex;
  gap: 8px;
  align-items: center;
  white-space: nowrap;
  padding: 0px 15px;
  border-radius: 4px;
  background-color: white;
  background-color: #e6ebff;
  z-index: 1;
}

.search-text{
  font-size: 12px;
  color: #000000;
}

.search-text-close-box{
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
}

.header-sub-container{
  height: 46px;
  display: flex;
  gap: 14px;
  justify-content: center;
  align-items: center;
  
}

.template-search-input-box{
  width: 323px;
  height: 44px;
  position: relative;
  z-index: 2;
}

.template-search-dd{
  width: 100%;
  height: auto;
  padding-top: 13px;
  z-index: 1;
  max-height: 170px;
  overflow-y: auto;
  position: absolute;
  top: 30px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid #e9e9e9;
  
}

.template-search-dd::-webkit-scrollbar {
  display: none;
}

.template-search-dd-list{
  width: 100%;
  height: 39px;
  font-size: 14px;
  color: #252525;
  padding: 0px 20px;
  align-content: center;
  background-color: white;
}

.template-search-dd-list:hover {
  cursor: pointer;
  background-color: #e6ebff;
}

.template-search-load{
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #475569;
  background-color: white;
  border-radius: 0px 0px 10px 10px;

}

.template-search-input-form{
  width: 100%;
  height: 44px;
  z-index: 2;
}

.template-search-input{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  outline: none;
  z-index: 2;
  position: relative;
  padding-left: 45px;
  padding-top:2px;
  background-color: white;
  border: 1px solid #e9e9e9;

}

.template-search-input::placeholder{
  font-size: 14px;
  color: #475569;
}

.template-submit-input{
  display: none;
}

.template-search-icon{
  width: 24px;
  height: 24px;
  position: absolute;
  top: 10px;
  left: 15px;
  display: flex;
  align-items: center;
  z-index: 2;
  justify-content: center;
}

.template-list-create-button {
  height: 44px;
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 0px 25px;
  background-color: #3d65fa;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-family: "puvi-medium";
  z-index: 1;
}

.template-list-create-plus-text {
  font-family: "puvi-medium";
  font-size: 20px;
  margin-left: 5px;
  margin-bottom: 2px;
  color: white;
  text-align: center;
  align-content: center;
}

.template-list-create-text {
  color: white;
  font-family: "puvi-medium";
  white-space: nowrap;
  font-size: 14px;
}


.template-table-container {
  width: 97.5%;
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px ;
}

.template-table-sub-container {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow-y: scroll;
  border: 1px solid #e9e9e9;
}

.template-table-sub-container::-webkit-scrollbar{
  display: none;
}

.template-loading {
  width: 100%;
  height: calc(100% - 57px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-top: none;
}


.template-pagination-body {
  width: 100%;
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.template-table {
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
}

.template-table thead {
  width: 100%;
  height: 56px;
  font-size: 14px;
  background-color: #e6ebff;
}

.template-table th,
.template-table td {
  padding: 10px;
  text-align: left;
  font-size: 14px;
  color: #475569;
  border-bottom: 1px solid #e9e9e9;
}

.template-table th{
  color: #000000;
}

.template-table-row {
  height: 54px;
  position: relative;
  background-color: white;
  cursor: pointer;
}

.template-action-box {
  width: 80px;
  height: 90%;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 2px;
  right: 0px;
  transition: 0.3s;
  transform: translateX(80px);
  background-color: white;
  overflow: hidden;
}

.template-table-row:hover .template-action-box {
  transform: translateX(20px);
}

.template-action-edit-btn{
  width: 30px;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #3D65FA;
  cursor: pointer;
  border: none;
  font-size: 14px;
  background-color: white;
  text-decoration: underline;
}

.template-action-edit-btn img {
  width: 22px;
  height: 22px;
}

.template-empty-msg-box{
  width: 100%;
  height: calc(100% - 57px);
  display: flex;
  align-items: center;
  justify-content: center;
}


