/* pagination Style */
.dynamic-pagination-body {
  width: 179px;
  height: 44px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: white;
  border: 1px solid #e9e9e9;
  /* position: absolute; */
}

.dynamic-pagination-selectors {
  /* width: 80px; */
  height: 80%;
  cursor: pointer;
  padding-right: 3px;
  border-radius: 4px;
  border: none;
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}


.dynamic-pagination-selectors-img {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dynamic-pagination-selectors-img img {
  width: 100%;
  height: 100%;
}

.dynamic-pagination-selectors-name {
  font-size: 14px;
  font-family: "puvi-medium";
  color: #475569;
}

.dynamic-pagination-count {
  height: 24px;
  font-size: 14px;
  color: #000000;
  font-family: "puvi-medium";
  padding: 0px 10px;
  text-align: center;
  align-content: center;
  border-left: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
}

/* ChatEnableConformation Style Start */
.chatenable-conformation-body {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  /* transform: scale(0); */
}

.chatenable-conformation-sub-body {
  width: 500px;
  height: 280px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.chatenable-conformation-heading-body {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatenable-conformation-warring-img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.chatenable-conformation-content-body {
  width: 100%;
  height: 80%;
  display: flex;
  gap: 28px;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  /* border: 1px solid red; */
}

.chatenable-conformation-content1-box {
  margin: 15px 0px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.chatenable-conformation-content1 {
  width: 80%;
  font-size: 16px;
  font-family: "puvi-medium";
  text-align: start;
  padding: 0px 2px;
}

.chatenable-conformation-check-box {
  height: 38px;
}

/* Toogle Button */
.chatenable-push-toggle {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chatenable-toggle-switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
}

.chatenable-toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.chatenable-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  transition: 0.4s;
  cursor: pointer;
}

.chatenable-slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
  cursor: pointer;
}

.chatenable-toggle-switch input:checked + .chatenable-slider {
  background-color: #8bc1f0;
}

.chatenable-toggle-switch input:checked + .chatenable-slider:before {
  transform: translateX(21px);
}

.chatenable-conformation-content2-box {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.chatenable-conformation-content2-box button {
  min-width: 108px;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.chatenable-conformation-save-btn {
  color: #ffffff;
  background-color: #0086ff;
}

/* Template Drop Down Style */
.tool-template-dd-body {
  position: relative;
}

.tool-template-dd-header-body {
  width: 100%;
  height: 44px;
  z-index: 1;
  position: relative;
  border-radius: 10px;
  padding-left: 16px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: 1px solid #e9e9e9;
}

.tool-template-dd-header {
  font-size: 14px;
  color: #202224;
  font-family: "puvi-medium";
}

.tool-template-dd-header-img-box {
  width: 24px;
  height: 24px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tool-template-dd-header-img-box img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.tool-template-dd-sub-box {
  width: 100%;
  max-height: 200px;
  position: absolute;
  top: 39px;
  background-color: white;
  border: 1px solid #e9e9e9;
  border-top: none;
  border-radius: 0 0 10px 10px;
  box-sizing: border-box;
  padding-top: 5px;
  overflow-y: auto;
  z-index: 3;
}

.tool-template-dd-sub-box::-webkit-scrollbar {
  display: none;
}

.tool-template-dd-list-body {
  width: 100%;
  height: 44px;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tool-template-dd-list-body:hover {
  background-color: #e6ebff;
}

/*Confomation DropDown  */
.drop-down-chat-header-assignee {
  font-size: 12px;
  color: #475569;
  height: 15px;
  font-family: "puvi-semi-bold";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90px;
  text-transform: capitalize;
}

.chat-header-assignee-drop-down-main-body {
  width: 100%;
  height: 100%;
}

.chat-header-assignee-label-drop-down-box {
  width: 100%;
  height: 37px;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
}

.chat-header-assignee-label-drop-down-sub-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;
}

.chat-header-assignee-box {
  width: 100%;
  /* min-width: 140px; */
  height: 30px;
  z-index: 3;
  position: relative;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  display: flex;
  gap: 7px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e9e9e9;
  background-color: white;
}

.chat-header-assignee-label-img-box {
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: space-between;
}

.chat-header-assignee-label-img-box img {
  width: 19px;
  height: 19px;
}


.chat-header-assignee-drop-down-body {
  width: 99.97%;
  min-height: 40px;
  max-height: 100px;
  overflow-y: auto;
  position: absolute;
  top: 75%;
  left: 0px;
  z-index: 1;
  padding: 3px 0px;
  padding-top: 7px;
  border-radius: 0px 0px 10px 10px;
  background-color: white;
  border: 1px solid #e9e9e9;
  border-top: none;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 3px 5px;
}

.chat-header-assignee-drop-down-body::-webkit-scrollbar {
  display: none;
}



.chat-header-assignee-drop-down-value {
  height: 30px;
  padding: 0px 10px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  justify-content: space-between;
}

.chat-header-assignee-label-edit-img {
  opacity: 0;
}

.chat-header-assignee-body:hover .chat-header-assignee-label-edit-img {
  opacity: 1;
}

.chat-header-assignee-drop-down-value:hover {
  background-color: #e6ebff;
}

/* Business popup form start */

.business-popup-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
}

.business-popup-form-container {
  background: white;
  padding: 60px;
  border-radius: 10px;
  width: 80%;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.business-popup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.business-popup-form-title {
  font-size: 22px;
  font-family: "puvi-bold";
  margin-bottom: 12px;
}

.business-popup-form-description {
  font-size: 14px;
  color: #555;
  font-family: "puvi-regular";
  margin-bottom: 20px;
}

.business-popup-form-inputs-container {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.business-popup-form-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.business-popup-form-label {
  margin-left: 30px;
  font-size: 16px;
  color: #475569;
  font-family: "puvi-Medium";
}

.business-popup-input-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 5px 0px 0px 10px;
}

.business-popup-input-container .error, .business-popup-form-checkbox-container .error {
  text-align: start;
}

.business-popup-form-input {
  width: 100%;
  height: 44px;
  outline: none;
  font-size: 14px;
  text-indent: 8px;
  border-radius: 10px;
  font-family: "puvi-Regular";
  border: 1px solid #e9e9e9;
  background-color: #ffffff;
}

.business-popup-form-checkbox-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: 20px;
  margin-top: 10px;
}

.business-popup-form-checkbox-container a {
  color: #3d65fa;
}

.business-popup-form-checkbox {
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-right: 5px;
  margin-top: 10px;
}

.business-popup-form-checkbox-label {
  font-size: 14px;
  font-family: "puvi-regular";
}

.business-popup-form-save-btn {
  width: 200px;
  padding: 10px;
  background: #3d65fa;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.business-popup-form-save-btn:disabled {
  background: #ccc;
}

/* Business popup form end */

/* Timezone field start */

.time-zone-dropdown-container {
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 2;
}

.time-zone-dropdown-value-box {
  width: 100%;
  height: 44px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0px 10px;
  z-index: 2;
  border: 1px solid #e9e9e9;
  background-color: #ffffff;
}

.time-zone-dropdown-value {
  font-size: 14px;
  color: #333;
}

.time-zone-dropdown-arrow {
  transition: 0.3s;
  width: 24px;
  height: 24px;
}

.time-zone-dropdown-dd-body {
  width: 100%;
  min-height: 40px;
  max-height: 160px;
  overflow-y: auto;
  position: absolute;
  top: 38px;
  z-index: 1;
  padding-top: 5px;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #e9e9e9;
  background-color: white;
}

.time-zone-dropdown-dd-body::-webkit-scrollbar {
  display: none;
}

.time-zone-dropdown-dd-list-value {
  width: 100%;
  height: 44px;
  color: #475569;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  font-size: 14px;
  font-family: "puvi-regular";
}

.time-zone-dropdown-dd-list-value-content {
  text-align: start;
}

.time-zone-dropdown-dd-list-value:hover {
  background-color: #f1f1f1;
}

.time-zone-dropdown-dd-tick-box {
  display: inline-block;
  margin-left: 10px;
}

.time-zone-dropdown-dd-list-value:last-child {
  border-bottom: none;
}

/* time zone field end */
/* Search And DropDown Style */


.search-select-textarea-body {
  border-radius: 10px;
  position: relative;
  z-index: 2;
}

.search-select-textarea-body input {
  width: 100%;
  height: 44px;
  resize: none;
  border-radius: 10px;
  outline: none;
  padding: 10px;
  z-index: 2;
  position: relative;
  border: none;
}

.search-select-dd-content-box {
  width: 100%;
  max-height: 120px;
  position: absolute;
  top: 85%;
  overflow-y: auto;
  border: 1px solid #e1e1e1;
  padding-top: 10px;
  border-radius: 0px 0px 10px 10px;
  background-color: white;
  z-index: 1;
}

.search-select-dd-content-box::-webkit-scrollbar {
  display: none;
}

.search-select-dd-title{
  font-size: 12px;
  text-indent: 2px;
  margin-bottom: 5px;
  color: #475569;
  font-family: "puvi-medium";
}

.search-select-dd-body {
  position: relative;
  z-index: 2;
}

.search-select-textarea-body {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border: 1px solid #e9e9e9;
  min-height: 40px;
  cursor: text;
  overflow-y: auto;
  z-index: 2;
  background-color: white;
}

.search-selected-items {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: flex-start;
}



.search-selected-close-icon {
  margin-left: 5px;
  cursor: pointer;
  font-weight: bold;
  color: red;
}

.search-select-error-text{
  position: absolute;
}

.search-select-dd-btn-box{
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  padding-right: 2px;
  position: absolute;
}

.search-select-btn{
  padding: 0px 15px;
  height: 30px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.search-select-cancel-btn{
  color: #475569;
  background: #E9E9E9;

}

.search-select-save-btn{
  color: #FFFFFF;
  background-color: #3D65FA;
}

.search-select-dd-list {
  width: 100%;
  height: 44px;
  font-size: 14px;
  cursor: pointer;
  text-indent: 10px;
  align-content: center;
  background-color: white;
}

.search-select-dd-list-new-btn{
  color: #3D65FA;
}

.search-select-dd-list:hover {
  background: #E6EBFF;
}

.search-selected-item {
  padding: 5px 10px;
  border-radius: 10px;
  margin: 5px;
  font-size: 12px;
  background-color: #E6EBFF;
}

.search-select-no-item-box{
  width: 100%;
  height: 120px;
  position: absolute;
  top: 85%;
  overflow-y: auto;
  border: 1px solid #e1e1e1;
  padding-top: 10px;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 1;
  font-size: 14px;
  color: #475569;
}

