.home-page-body {
  width: 100%;
  height: calc(100% - 49px);
  display: flex;
  gap: 8vh;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding-top: 5vh;
}

.step3 {
  gap: 0vh;
  padding-top: 10vh;
}

.checkcomplete {
  background-color: red;
}

/* HomeHeader compont style */
.home-header {
  width: 100%;
  height: 10vh;
  max-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tracker {
  width: 200px;
  height: 100%;
  display: flex;
  justify-content: space-around;
  position: relative;
}

.stage-container {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.stage {
  width: 18px;
  height: 18px;
  font-size: 10px;
  border-radius: 50%;
  color: #1877f2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none;
  border: 1px solid #1877f2;
}

.stage.completed {
  color: white;
  background-color: #1877f2;
}

.stage-label {
  font-size: 12px;
  margin-top: 3px;
  color: #1877f2;
}

.tracking-line-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.tracking-line {
  width: 95px;
  height: 2px;
  position: absolute;
  top: 37%;
  background-color: #ccc;
}

.tracking-line.incompleted {
  width: 95.1px;
  margin-left: 0.1px;
  position: absolute;
  top: 32%;
  background-color: #ccc;
}

.tracking-line.completed {
  width: 95.1px;
  position: absolute;
  top: 33%;
  background-color: #1877f2;
}

.stage.incompleted {
  color: #ccc;
  border-color: #ccc;
}

.stage-label.incompleted {
  color: #ccc;
}

/* Home Component Style */
.home-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.activate-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: aliceblue;
  font-size: 25px;
  background: rgba(0, 0, 0, 0.5);
}

/*FacebookLogin Component Style  */
.facebook-login-steps {
  width: 80%;
  display: flex;
  gap: 20px;
  align-items: center;
  flex-direction: column;
  /* background-color: pink; */
}

.facebook-login-header {
  height: 20%;
  width: 70%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.facebook-login-title {
  font-size: 24px;
  font-family: "puvi-semi-bold";
}

.facebook-login-content {
  font-size: 16px;
  margin-top: 12px;
  font-family: "puvi-regular";
}

.facebook-login-main {
  width: 70%;
  height: 72%;
  max-width: 765px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.facebook-login-image {
  width: 35%;
  height: 208px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.facebook-login-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.facebook-login-instructions {
  width: 60%;
  display: flex;
  gap: 12px;
  align-items: start;
  flex-direction: column;
  justify-content: center;
}

.facebook-login-instructions div {
  font-size: 16px;
  font-family: "puvi-regular";
}

.facebook-login-instructions-heading {
  width: 100%;
}

.facebook-login-instructions-content {
  max-width: 459px;
  width: auto;
}

.facebook-login-footer {
  width: 70%;
  display: flex;
  align-items: start;
  justify-content: center;
}

.facebook-login-button {
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  padding: 12px 24px;
  font-family: "puvi-bold";
  background-color: #0086ff;
}

.facebook-login-button.disabled {
  background-color: #a3c2f3;
  cursor: not-allowed;
  opacity: 0.6;
}

/*Activactionpage Compount Style  */
.facebook-login-steps2 {
  width: 80%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
}

.facebook-login-header2 {
  height: 20%;
  width: 70%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.facebook-login-main-step2 {
  width: 73%;
  max-width: 798px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.facebook-login-step2-image {
  width: 35%;
  height: 300px;
}

.facebook-login-step2-image img {
  width: 100%;
  height: 100%;
}

.facebook-activation-container {
  width: 60%;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.facebook-activation-instruction {
  width: 100%;
  max-width: 485px;
}

.facebook-activation-instruction div {
  width: 100%;
  font-size: 16px;
}

.facebook-activation-input {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.code-input {
  width: 35%;
  padding: 10px;
  margin-bottom: 12px;
  outline: none;
  border: 1px solid #030303;
  border-radius: 5px;
  font-size: 14px;
  font-family: "puvi-regular";
}

.code-input::placeholder {
  font-size: 14px;
}

.activate-button {
  width: 35%;
  padding: 10px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  transition: 0.3s;
}

.activate-button-text {
  font-size: 14px;
  font-family: "puvi-semi-bold";
}

.activate-button:hover {
  background-color: #0056b3;
}

.facebook-activation-info {
  max-width: 490px;
  opacity: 1;
}

.facebook-activation-info div {
  color: #ff6357;
  font-size: 15px;
  font-family: "puvi-regular";
}

.facebook-activation-info-hide {
  max-width: 500px;
  opacity: 0;
}

.facebook-activation-info-hide div {
  color: #ff6357;
  font-size: 15px;
}

/* Activation3rdstep Component Style */
.facebook-login-final-main-box {
  width: 73%;
  height: 72%;
  max-width: 798px;
  /* max-width: 765px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: burlywood; */
}

.facebook-login-step3-image {
  width: 35%;
  height: 208px;
  /* background-color: #ff6357; */
}

.facebook-login-step3-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* display: none; */
}

.login-final-step-instructions {
  width: 50%;
  width: 480px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  align-items: start;
  /* flex-direction: column; */
  justify-content: space-between;
  /* background-color: grey; */
}

.display-name-box {
  width: 200px;
  height: 50px;
  /* background-color: red; */
}

.display-name-box-name-1 {
  font-size: 14px;
}

.display-name-box-name-2 {
  margin-top: 4px;
  font-size: 18px;
}

.login-display-number-box {
  width: 200px;
  height: 50px;
  /* background-color: blue; */
}

.login-display-complete-content {
  width: 551px;
  height: 50px;
  font-size: 14px;
  font-family: "puvi-regular";
  /* background-color: blueviolet; */
}

.facebook-login-final-points-note-box {
  width: 68%;
  max-width: 743px;
  /* background-color: #0086ff; */
  /* height: 80px; */
  /* background-color: #ff6357; */
}

.facebook-login-final-points-note-box div {
  margin-bottom: 8px;
  font-size: 14px;
}

.point-bold {
  font-family: "puvi-semi-bold";
}

.activation-button-container
{
  display: flex;
  width: 70%;
  align-items: center;
  justify-content: center;
}

.facebook-login-final-button-box {
  width: 40%;
  height: 6vh;
  max-height: 36px;

  display: flex;
  justify-content: center;
  /* background-color: #ff6357; */
}
.facebook-login-final-button {
  width: 40%;
  max-width: 175px;
  height: 100%;
  border: none;
  background-color: #0086ff;
  cursor: pointer;
}

.whatsapp-manager-button
{
  width: 210px;
  /* max-width: 200px; */
  height: 6vh;
  max-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 10px 15px; */
  border: none;
  background-color: #0086ff;
  color: #fff;
  font-family: "puvi-semi-bold";
  cursor: pointer;
}

.whatsapp-manager-button-div a
{
  /* padding: 8px 12px; */
  /* background-color: #030303; */
  text-decoration: none;
  
}

.facebook-login-final-button div {
  color: white;
  font-family: "puvi-semi-bold";
}

/*  */
.activation-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.activation-popup-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 300px;
}

.activation-close-button {
  position: absolute;
  top: 0px;
  right: 15px;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
}

.activation-popup-content h2 {
  margin-bottom: 20px;
  color: #333;
}

.warning-message {
  color: red;
  margin-bottom: 10px;
}

.loading-div {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
}
