.toast-notification {
  position: fixed;
  top: 10px;
  right: 10px;
  padding: 16px 20px;
  border-radius: 8px;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
  z-index: 1000;
  max-width: 350px;
  font-family: Arial, sans-serif;
  line-height: 1.5;
  animation: fadeIn 0.4s ease-out, slideOut 0.4s ease-in forwards;
  animation-delay: 3s; /* Adjust to control notification display time */
}

.toast-notification:hover {
  animation-play-state: paused;
}

.toast-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.toast-title {
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}

.toast-body {
  font-size: 14px;
  color: #f9f9f9;
  margin: 5px 0;
}

.toast-close-btn {
  background: none;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  padding: 0;
}

.toast-close-btn:hover {
  color: #ddd;
}

/* pre {
  background: rgba(18, 33, 99, 0.1);
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  color: #000;
  margin-top: 10px;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
} */

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}
