.template-action-page-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.template-action-page-header {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
}

.template-action-header-title-section {
  display: flex;
  gap: 10px;
  align-items: center;
}

.template-action-header-back-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
}

.template-action-header-back-icon img {
  width: 20px;
  height: 20px;
  z-index: 1;
}

.template-action-header-title-section button {
  padding: 8px 10px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  z-index: 1;
}

.template-no-content-div
{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.template-action-main-container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  height: calc(100% - 60px);
}

.template-action-main {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.template-action-preview {
  width: 345px;
  overflow-y: scroll;
}

.template-detail {
  width: 100%;
  height: 44px;
  display: flex;
  padding: 10px;
}

.detail-label {
  width: 120px;
  color: black;
  font-size: 14px;
  font-family: "puvi-medium";
}

.detail-value {
  font-size: 14px;
  color: #475569;
  font-family: "puvi-medium";
}

.template-action-buttons-container {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 30px;
}

.template-action-buttons-container button {
  min-width: 82px;
  height: 40px;
  padding: 0px 9px;
  cursor: pointer;
  border-radius: 10px;
  background-color: white;
  font-family: "puvi-medium";
}

.template-action-button-edit {
  color: #6383fc;
  border: 1px solid #6383fc;
}

.template-action-button-Publish {
  color: #6383fc;
  border: 1px solid #6383fc;
}

.template-action-button-activate {
  color: #22c55e;
  border: 1px solid #22c55e;
}

.template-action-button-deactivate {
  color: #e17375;
  border: 1px solid #e17375;
}

.action-disabled {
  pointer-events: none;
  border: 1px solid rgb(151, 151, 151);
  color: rgb(151, 151, 151);
}

.template-load-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
