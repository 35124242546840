.analytics-container {
  width: 100%;
  height: calc(100% - 49px);
  display: flex;
}

.analytics-side-bar-container {
  width: 18.5%;
  height: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  border-right: 1px solid #e9e9e9;
  background-color: #ffffff;
}

.analytics-side-bar-title {
  width: 100%;
  font-size: 20px;
  text-indent: 18px;
  color: #000000;
  font-family: "puvi-medium";
}

.whatsApp-manager-btn-link {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 10px;
  padding-left: 18px;
  display: flex;
  justify-content: start;
  text-decoration: none;
}

.whatsApp-manager-btn {
  width: 95%;
  display: inline-flex;
  align-items: center;
  color: #3d65fa;
  border: none;
  text-decoration: underline;
  /* border: 1px solid #3D65FA; */
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 10px;
  /* font-family: "puvi-medium"; */
  background-color: white;
}

.analytics-main-container {
  width: 81.5%;
  background-color: #ffffff;
  height: 100%;
}

/* Sidebar Menu */
.analytics-side-bar-menu {
  width: 100%;
  height: 55px;
  cursor: pointer;
  font-size: 16px;
  color: #000000;
  padding-left: 19px;
  align-content: center;
  font-family: "puvi-regular";
}

.analytics-side-bar-menu.active {
  color: white;
  background-color: #3d65fa;
}

/* Main Header */
.analytics-main-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
  height: 7vh;
}

.analytics-main-header button {
  min-width: 96px;
  border: none;
  cursor: pointer;
  color: #475569;
  border-radius: 4px;
  padding: 8px 12px;
  background-color: #E9E9E9;
}


/* Stats Container */
.stats-container {
  height: 75vh;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pie-chart-container {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.pie-chart {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.select-options-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10;
  margin-top: 30px;
}

.date-range-selector,
.conversation-type-selector {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 20px;
  margin-bottom: 10px;
  font-family: Arial, sans-serif;
}

.date-range-selector label,
.conversation-type-selector label,
.conversation-metric-selector > label {
  font-size: 16px;
  color: #475569;
  font-family: "puvi-medium";
}

.date-range-selector .tool-template-dd-header-body,
.conversation-type-selector .tool-template-dd-header-body {
  height: 35px;
}

.date-range-selector .tool-template-dd-sub-box,
.conversation-type-selector .tool-template-dd-sub-box {
  top: 29px;
}

.date-range-selector .tool-template-dd-list-body,
.conversation-type-selector .tool-template-dd-list-body{
  height: 35px;

}

.conversation-metric-selector {
  padding: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.conversation-metric-selector label {
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.conversation-metric-selector div {
  display: flex;
  gap: 15px;
}

.conversation-metric-selector input[type="radio"] {
  margin-right: 6px;
}

.server-error-body {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
