.toast {
  position: fixed;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 250px;
  z-index: 1000;
  transition: top 0.5s ease;
}

.toast.toast-success {
  background-color: #4caf50;
}

.toast.toast-error {
  background-color: #f44336;
}

.toast-show {
  top: 20px;
}

.toast-message {
  flex: 1;
}

.toast-close-btn {
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin-left: 15px;
}

.page-load-header {
  height: 57px;
  padding: 0px 24px;
  display: flex;
  gap: 35px;
  align-items: center;
  justify-content: start;
  background-color: white;
  border-bottom: 1px solid #e9e9e9;
}

.page-load-header-part1 {
  width: 123px;
  height: 40px;
  border-radius: 6px;
  background-color: #e9e9e9;
}

.page-load-header-part2 {
  display: flex;
  gap: 10px;
}

.page-load-header-part2-box {
  width: 80px;
  height: 40px;
  border-radius: 6px;
  background-color: #e9e9e9;
}

.page-load-main {
  width: 100%;
  height: calc(100% - 58px);
  display: flex;
  /* border-bottom: 1px solid #e9e9e9; */
}

.page-load-main2 {
  width: 100%;
  height: 100%;
  display: flex;
}

.page-load-main-part1 {
  width: 28%;
  height: 100%;
  background-color: white;
  border-right: 1px solid #e9e9e9;
}

.page-load-main-part1-head {
  height: 72px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e9e9e9;
}

.page-load-main-part1-head-box1 {
  width: 227px;
  height: 44px;
  border-radius: 6px;
  background-color: #e9e9e9;
}

.page-load-main-part1-head-box2 {
  width: 44px;
  height: 44px;
  border-radius: 6px;
  margin-left: 26px;
  background-color: #e9e9e9;
}

.page-load-main-part1-head-box3 {
  width: 44px;
  height: 44px;
  border-radius: 6px;
  margin-left: 10px;
  background-color: #e9e9e9;
}

.page-load-main-part1-body {
  width: 100%;
  height: calc(100% - 72px);
  padding: 0px 18px;
}

.page-load-main-part1-body-value {
  width: 100%;
  height: 83px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e9e9e9;
}

.page-load-main-part1-body-sub-value {
  width: 100%;
  height: 48px;
  display: flex;
  gap: 12px;
  align-items: center;
}

.page-load-main-part1-body-img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #e9e9e9;
}

.page-load-main-part1-body-value-box {
  width: calc(100% - 50px);
  height: 100%;
  display: flex;
  gap: 4px;
  flex-direction: column;
}

.page-load-main-part1-body-name {
  width: 100%;
  height: 18px;
  border-radius: 5px;
  background-color: #e9e9e9;
}

.page-load-main-part2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.page-load-main-part2-box1 {
  width: 72%;
  height: 100%;
  border-right: 1px solid #e9e9e9;
}

.page-load-main-part2-box1-header {
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
}

.page-load-main-part2-box1-sub-header {
  width: 100%;
  height: 100%;
  padding: 0px 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-load-main-part2-box1-header-profile {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #e9e9e9;
}

.page-load-main-part2-box1-header-options-box {
  width: 323px;
  height: 48px;
  display: flex;
  gap: 18px;
  align-items: center;
}

.page-load-main-part2-box1-header-option {
  width: 110px;
  height: 100%;
  border-radius: 10px;
  background-color: #e9e9e9;
}

.page-load-main-part2-box1-header-option3 {
  width: 46px;
  height: 100%;
  border-radius: 10px;
  background-color: #e9e9e9;
}

.page-load-main-part2-box2 {
  width: 27%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border:1px solid #E9E9E9 ; */
}

.page-load-main-box2-box1 {
  width: 80%;
  height: 83px;
  border-radius: 10px;
  margin-top: 49px;
  background-color: #e9e9e9;
}

.page-load-main-box2-box2 {
  width: 80%;
  height: 35px;
  margin-top: 10px;
  border-radius: 10px;
  background-color: #e9e9e9;
}

.page-load-main-box2-box3 {
  width: 80%;
  height: 63px;
  margin-top: 20px;
}

.page-load-main-box2-box3 div {
  width: 100%;
  height: 100%;
  margin-top: 10px;
  border-radius: 10px;
  background-color: #e9e9e9;
}

/* Skeleton Animation Styles */
.skeleton {
  position: relative;
  overflow: hidden;
  background: linear-gradient(to right, #e9e9e9 0%, #f5f5f5 50%, #e9e9e9 100%);
  background-size: 200% 100%;
  animation: skeleton-loading 2s infinite;
}

/* Animation Keyframes */
@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

/* Local Load Style */
.local-load-body {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.local-load-content-box {
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.local-load-content-img-box {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
}

.local-load-content-img-box img {
  width: 100%;
  height: 100%;
}

.local-load-content-text {
  width: 100%;
  color: #000000;
  text-align: center;
  font-family: "puvi-medium";
  white-space: none;
}

/*GlobalLoading  */

