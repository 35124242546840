.settings-container {
  width: 100%;
  height: calc(100% - 49px);
}

.settings-header {
  width: 100%;
  height: 46px;
  padding-top: 16px;
}

.settings-header-text {
  font-size: 20px;
  text-indent: 18px;
  color: #000000;
  font-family: "puvi-medium";
}

.settings-sub-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.settings-sidebar {
  width: 18.5%;
  height: 100%;
  border-right: 1px solid #e9e9e9;
  background-color: #ffffff;
}

.settings-main-container {
  width: 81.5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  /* border: 1px solid red; */
}

.settings-main-container-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.75);
}

.settings-sidebar header h4 {
  color: #000;
  font-size: 14px;
  font-family: "puvi-semi-bold";
  text-align: left;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: 1px solid #edf1f3;
  padding: 10px;
}

.setting-menu {
  height: 55px;
  padding-left: 16px;
  color: #000;
  cursor: pointer;
  font-size: 16px;
  text-align: left;
  font-weight: 400;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: start;
  background-color: #ffffff;
}

.setting-menu:hover {
  background-color: #e6ebff;
}

.setting-menu img,
.setting-menu.selected img {
  width: 19px;
  height: 19px;
}

.setting-menu.selected {
  box-shadow: none;
  color: #ffffff;
  background-color: #3d65fa;
}

.restricted-style-body {
  cursor: not-allowed;
}

.restricted-style {
  opacity: 0.5;
  pointer-events: none;
}

.restricted-style:hover {
  background-color: white;
}

/* Rules details page start */

.settings-rules-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
}

.settings-rules-container-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.settings-rules-container-header p {
  font-size: 16px;
  color: #000000;
  font-family: "puvi-medium";
}

.rule-edit-btn {
  width: 82px;
  height: 40px;
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: center;
  border: 1px solid #6383fc;
  border-radius: 10px;
  cursor: pointer;
}

.rule-edit-btn p {
  font-size: 14px;
  font-weight: 400;
  color: #6383fc;
}

.settings-rules-details-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  margin-top: 23px;
}

.settings-rules-details-container::-webkit-scrollbar {
  display: none;
}

.details-clauses-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  padding-top: 0px;
  margin-bottom: 10px;
  /* border: 1px solid green; */
}

.details-clauses-container > p {
  color: #000000;
  font-size: 14px;
  font-family: "puvi-medium";
  text-transform: uppercase;
}

.details-clause {
  width: 100%;
  height: auto;
  min-height: 89px;
  display: flex;
  align-self: center;
  justify-content: space-between;
  padding: 22px;
  border-radius: 10px;
  margin-top: 10px;
  border: 1px solid #e9e9e9;
}

.details-clause-criteria {
  width: 48%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
}

.details-clause-criteria span {
  font-size: 18px;
  color: #202224;
  line-height: 25px;
}

.details-clause-fulfillment {
  width: 48%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
}

.details-clause-fulfillment p {
  font-size: 16px;
  font-weight: 400;
  color: #202224;
  margin-top: 14px;
}

.details-clause-fulfillment span {
  font-size: 14px;
  color: #475569;
  font-family: "puvi-medium";
}
/* Rules details page end */

/* Rules form page start */
.settings-rules-form-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.settings-rules-form-container-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 0px 20px;
}

.settings-rules-form-container-header p {
  font-size: 16px;
  font-family: "puvi-medium";
}

.settings-rules-form-container-header div {
  display: flex;
  gap: 9px;
  align-items: center;
  justify-content: space-around;
}

.rule-form-save-btn {
  width: 96px;
  height: 30px;
  border-radius: 4px;
  border: none;
  color: #fff;
  cursor: pointer;
  background-color: #3d65fa;
}

.rule-form-cancel-btn {
  width: 96px;
  height: 30px;
  border-radius: 4px;
  border: none;
  color: #475569;
  cursor: pointer;
  background-color: #e9e9e9;
}

/* Rules form Page end */

/* ChangePassword Form Style Start */

.chnagepasword-container {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 12;
}

.changepassword-form-body {
  width: auto;
  height: auto;
  padding: 15px;
  border-radius: 5px;
  z-index: 5;
  background-color: white;
}

.changepassword-sub-body {
  width: 350px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.change-password-heading {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.change-password-title {
  font-size: 24px;
}

.change-password-cancel {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.change-password-cancel:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}

.change-password-cancel img {
  width: 20px;
  height: 20px;
}

.change-password-body {
  width: 100%;
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.change-password-input-box {
  width: 100%;
  height: 80px;
  display: flex;
  gap: 3px;
  flex-direction: column;
  position: relative;
}

.change-password-input-box label {
  font-size: 14px;
  text-indent: 4px;
}

.change-password-input-box input {
  width: 99%;
  height: 38px;
  outline: none;
  font-size: 14px;
  text-indent: 5px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.change-password-input-box p {
  padding: 0;
  font-size: 12px;
  color: red;
  text-indent: 3px;
  opacity: 0;
}

.form-eye-body {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 33px;
  right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-eye-body img {
  width: 90%;
  height: 90%;
  opacity: 0.5;
}

.change-password-alert-body {
  width: 100%;
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding: 5px 0px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.change-password-alert-box {
  width: 95%;
  height: 20px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.change-password-alert-box-title {
  font-size: 14px;
  padding: 0px 10px;
  font-family: "puvi-semi-bold";
  color: rgba(0, 0, 0, 0.8);
}

.change-password-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}

.change-password-alert-colour {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
}

.change-password-button {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.change-password-button button {
  width: 100%;
  border: none;
  transition: 0.5s;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  padding: 10px 0px;
  cursor: pointer;
  background-color: #0086ff;
}
/* ChangePassword Form Style End */

/* Users Body Style Start*/
.users-body {
  width: 100%;
  height: 100%;
}

.users-sub-body {
  width: 100%;
  height: calc(100% - 64px);
  display: flex;
  justify-content: center;
}
/* Users Body Style End*/

/* UsersHeader Style Start */
.users-header-body {
  width: 100%;
  height: 64px;
  padding: 20px 20px;
  padding-bottom: 0px;
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.users-header-title {
  font-size: 16px;
  color: #000000;
  font-family: "puvi-medium";
}

.users-header-button {
  padding: 14px 37px;
  max-height: 44px;
  background-color: #3d65fa;
  border: 1px solid #3d65fa;
  color: white;
  border: none;
  display: flex;
  gap: 3px;
  align-items: center;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
}

.users-header-button span {
  font-size: 20px;
}
/* UsersHeader Style End */

/* UsersSideBar Style Start */
.users-sidebar-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: start;
  gap: 10px;
  padding: 0px 20px;
  padding-top: 10px;
  overflow-y: auto;
}

.users-sidebar-content {
  width: 253px;
  height: 63px;
  font-size: 12px;
  text-align: start;
  border-radius: 10px;
  padding-left: 15px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px solid #e9e9e9;
  box-shadow: rgba(100, 100, 100, 0.1) 0px 3px 5px 0px;
}

.users-sidebar-content:hover {
  cursor: pointer;
  transition: 0.2s;
  background-color: #edf1f3;
}

.users-sidebar-content.selected {
  border: none;
  color: black;
  border: 1px solid #3d65fa;
  background-color: #e6ebff;
  box-shadow: none;
}

.user-content-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.user-content {
  font-size: 12px;
  color: #000000;
  font-family: "puvi-medium";
}

.user-content-name {
  width: 100%;
  padding-right: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-content-name-box {
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-content-mail {
  color: #475569;
  font-family: "puvi-regular";
}

.users-sidebar-details-box {
  display: flex;
  gap: 2px;
  align-items: start;
  justify-content: start;
  flex-direction: column;
}

.users-sidebar-super-admin {
  width: 85px;
  height: 18px;
  text-align: center;
  align-content: center;
  font-size: 9px;
  border-radius: 5px;
  color: #6c643c;
  background-color: #ffecb4;
}

.users-sidebar-current-user {
  text-align: start;
  text-indent: 0px;
  padding: 3px 5px;
  font-size: 10px;
  border-radius: 5px;
  color: #474747f6;
  background-color: #d5fadc;
}
/* UsersSideBar Style End */

/* UsersMain Style Start */
.users-main-body {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: start;
  justify-content: right;
  background-color: #000000b2;
  z-index: 3;
}

.users-main-sub-body {
  width: 341px;
  height: 100%;
  background-color: white;
}

.users-main-body-click {
  width: 100%;
  height: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.75);
}

.users-main-body-heading {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  padding-left: 40px;
  background-color: #e6ebff;
  border-bottom: 1px solid #e9e9e9;
}

.users-main-body-heading-part1 p {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
  font-family: "puvi-semi-bold";
}

.users-main-body-heading-part1 {
  height: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
}

.users-main-body-heading-part2 {
  display: flex;
  gap: 10px;
  align-items: center;
}

.users-main-body-heading-part2 button {
  font-size: 14px;
  padding: 8px 22px;
  border-radius: 6px;
  cursor: pointer;
  background-color: white;
  text-transform: capitalize;
}

.user-main-close-body {
  cursor: pointer;
}

.users-main-body-heading-active-btn {
  padding: 5px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 10px;
  background-color: white;
  border: 1px solid #3d65fa;
}

.users-main-body-heading-delete-btn {
  padding: 5px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 10px;
  color: #c73f33;
  background-color: white;
  border: 1px solid #c73f33;
}

.users-main-form-body {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000b2;
}

.users-main-form {
  width: 350px;
  padding: 20px;
  border-radius: 6px;
  transition: 0.3s;
  background-color: white;
}

.users-main-form-headrer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.users-main-form-title {
  font-size: 16px;
  color: #333333;
  font-family: "puvi-medium";
}

.users-main-form-close-btn {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.2s;
  cursor: pointer;
}

.users-main-form-close-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.users-main-form-content-body {
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.users-main-form-label-input-body {
  width: 100%;
  min-height: 40px;
  display: flex;
  flex-direction: column;
}

.users-main-form-label-input-body label {
  width: 80px;
  font-size: 14px;
  color: #333333;
}

.users-main-form-label-input-body input {
  width: 100%;
  height: 35px;
  outline: none;
  margin-top: 8px;
  border-radius: 10px;
  padding: 0px 10px;
  color: #333333;
  background-color: #fff;
  border: 1px solid #e9e9e9;
}

.users-main-form-label-input-body p {
  font-size: 10px;
  color: red;
  margin-top: 2px;
}

.users-main-form-drop-down-body {
  width: 100%;
}

.users-main-form-drop-down {
  width: 100%;
  height: 35px;
  border-radius: 10px;
  font-size: 12px;
  padding: 0px 10px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  color: #333333;
  cursor: pointer;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  z-index: 1;
  position: relative;
}

.users-main-form-drop-down img {
  transition: 0.3s;
}

.users-main-form-drop-down-list-box {
  width: 99.5%;
  border-radius: 0px 0px 6px 6px;
  margin-top: -6px;
  margin-left: 1px;
  padding-top: 6px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.users-main-form-drop-down-list {
  width: 100%;
  height: 36px;
  font-size: 14px;
  color: #333333;
  align-content: center;
  padding: 0px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.users-main-form-drop-down-list img {
  width: 18px;
  height: 18px;
}

.users-main-form-drop-down-list:hover {
  background-color: #e6ebff;
}

.users-main-form-btn {
  width: 100%;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.users-main-form-btn button {
  display: flex;
  align-items: center;
  padding: 8px 22px;
  background-color: #434ee1;
  color: white;
  font-family: "puvi-medium";
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.users-main-body-details {
  margin-top: 30px;
  margin-left: 40px;
}

.users-main-details-box {
  width: 530px;
  height: auto;
  margin-top: 25px;
}

.users-main-details-title {
  width: 100px;
  height: 100%;
  font-size: 14px;
  font-family: "puvi-medium";
}

.users-main-details-value {
  width: auto;
  height: 100%;
  margin-top: 5px;
  color: #475569;
  font-size: 14px;
  display: flex;
}

.users-main-details-name-edit {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 7px;
  opacity: 0;
  transition: 0.3s;
  cursor: pointer;
}

.users-main-details-name-edit img {
  width: 100%;
  height: 100%;
}

.users-main-details-box:hover .users-main-details-name-edit {
  opacity: 1;
}

.users-main-details-role-edit {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 5px;
  margin-top: -1px;
  border-radius: 4px;
}

.users-main-details-role-edit img {
  width: 100%;
  height: 100%;
}

.user-edit-from-sub-body {
  width: 280px;
  height: 98px;
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 15px;
}

.user-edit-from-input-body {
  width: 160px;
  margin-top: 5px;
  position: relative;
}

.user-edit-from-input-body label {
  font-size: 12px;
  color: #475569;
  font-family: "puvi-medium";
}

.user-edit-from-input-body input {
  width: 100%;
  height: 35px;
  outline: none;
  margin-top: 5px;
  text-indent: 8px;
  font-size: 12px;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
}

.user-edit-from-input-body p {
  font-size: 10px;
  color: red;
  position: absolute;
  top: 100%;
}

.user-edit-from-btn-body {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 10px;
  align-items: start;
  justify-content: start;
}

.user-edit-from-sub-body button {
  width: 25px;
  height: 25px;
  margin-top: 17px;
  border: none;
  color: white;
  cursor: pointer;
  background-color: white;
}

.user-edit-from-sub-body button img {
  width: 100%;
  height: 100%;
}

.user-edit-from-close {
  width: 25px;
  height: 25px;
  margin-top: 17px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-edit-from-close img {
  width: 100%;
  height: 100%;
}

.users-main-details-dd-body {
  height: auto;
  display: flex;
  align-items: start;
  gap: 10px;
}

.users-main-details-dd-content {
  min-width: 150px;
  height: 35px;
  padding: 0px 10px;
  padding-right: 5px;
  margin-top: 5px;
  z-index: 2;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: 1px solid #e9e9e9;
}

.users-main-details-dd-content-value {
  color: #202224;
  font-size: 14px;
}

.users-main-details-dd-content-arrow {
  width: 18px;
  height: 18px;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.users-main-details-dd-content-arrow img {
  width: 100%;
  height: 100%;
}

.users-main-details-dd-list-body {
  width: 150px;
  margin-top: -7px;
  padding-top: 5px;
  min-height: 30px;
  max-height: 90px;
  overflow-y: auto;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #e9e9e9;
}

.users-main-details-dd-list-value {
  width: 100%;
  height: 30px;
  font-size: 14px;
  padding: 0px 10px;
  padding-right: 5px;
  color: #475569;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid white;
}

.users-main-details-dd-list-value:hover {
  cursor: pointer;
  background-color: #e6ebff;
}

.users-main-details-dd-tick-box {
  width: 10px;
  height: 10px;
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.users-main-details-dd-tick-box img {
  width: 100%;
  height: 100%;
}
.users-main-details-dd-open,
.users-main-details-dd-close {
  width: 25px;
  height: 25px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.users-main-details-dd-open img,
.users-main-details-dd-close img {
  width: 100%;
  height: 100%;
}
/* UsersMain Style End */

/* Organisation Style Start*/
.organisation-body {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.organisation-body-heading {
  width: 100%;
  font-size: 16px;
  color: #000000;
  text-indent: 20px;
  padding-top: 16px;
  text-transform: uppercase;
  font-family: "puvi-semi-bold";
}

.organisation-sub-body {
  margin-top: 16px;
}

.organisation-list-body {
  display: flex;
  gap: 20px;
  font-size: 14px;
  margin-top: 18px;
  margin-left: 20px;
  /* transition: 0.3s; */
}

.organisation-list-label {
  width: 110px;
  font-size: 14px;
  color: #000000;
  font-family: "puvi-medium";
}

.organisation-list-value-box {
  display: flex;
  gap: 5px;
  align-items: start;
  /* transition: 0.3s; */
}

.organisation-list-value {
  font-size: 14px;
  color: #475569;
  font-family: "puvi-medium";
  /* border: 1px solid red; */
}

.organisation-list-value-edit {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  /* transition: 0.3s; */
}

/* .organisation-list-time-zone-box {
  transition: 0.3s;
} */

.organisation-list-name-box:hover .organisation-list-name-edit {
  opacity: 1;
  cursor: pointer;
}

.organisation-list-time-zone-box:hover .organisation-list-time-zone-edit {
  opacity: 1;
  cursor: pointer;
}

.organisation-time-zone-dd-body {
  height: auto;
  display: flex;
  align-items: start;
  gap: 10px;
  position: absolute;
  z-index: 1;
}

.organisation-time-zone-dd-content {
  min-width: 90px;
  height: 35px;
  padding: 5px 10px;
  border-radius: 10px;
  padding-right: 5px;
  cursor: pointer;
  z-index: 2;
  position: relative;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e9e9e9;
  background-color: white;
}

.organisation-time-zone-dd-content-value {
  font-size: 14px;
  text-indent: 4px;
}

.organisation-time-formate-dd-content-value {
  display: flex;
  gap: 3px;
}

.organisation-date-formate-dd-content-value {
  display: flex;
  gap: 3px;
}

.time-format-value {
  width: 70px;
}

.date-format-value {
  width: 102px;
}

.organisation-time-zone-dd-content-arrow {
  width: 18px;
  height: 18px;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.organisation-time-zone-dd-content-arrow img {
  width: 100%;
  height: 100%;
}

.organisation-time-zone-dd-list-body {
  margin-top: -10px;
  padding-top: 10px;
  min-height: 30px;
  max-height: 150px;
  overflow-y: auto;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #e9e9e9;
  background-color: white;
}

.organisation-time-zone-dd-list-body::-webkit-scrollbar {
  display: none;
}

/* .organisation-time-zone-dd-list-body::-webkit-scrollbar-track {
  background-color: white;
}

.organisation-time-zone-dd-list-body::-webkit-scrollbar-thumb {
  background-color: #d8d2c2;
  border-radius: 2px;
} */

.organisation-date-zone-dd-list-body::-webkit-scrollbar {
  display: none;
}

.organisation-time-zone-dd-list-value {
  width: 100%;
  height: 35px;
  font-size: 14px;
  padding: 5px 10px;
  padding-right: 5px;
  display: flex;
  align-items: center;
  text-indent: 3px;
  color: #475569;
  justify-content: space-between;
  border: 1px solid white;
}

.organisation-time-zone-dd-list-value:hover {
  cursor: pointer;
  background-color: #e6ebff;
}

.organisation-time-zone-dd-tick-box {
  width: 10px;
  height: 10px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.organisation-time-zone-dd-tick-box img {
  width: 100%;
  height: 100%;
}

.organisation-time-zone-dd-open,
.organisation-time-zone-dd-close {
  width: 25px;
  height: 25px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.organisation-time-zone-dd-open img,
.organisation-time-zone-dd-close img {
  width: 100%;
  height: 100%;
}

.organisation-list-super-admin-value {
  cursor: pointer;
  color: #3d65fa;
  font-family: "puvi-medium";
}

.organisation-from-sub-body {
  display: flex;
  gap: 7px;
  align-items: start;
  justify-content: space-between;
}

.organisation-from-input-body {
  width: 160px;
  position: relative;
}

.organisation-from-input-body input {
  width: 100%;
  height: 35px;
  outline: none;
  margin-bottom: 5px;
  text-indent: 10px;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
}

.organisation-from-input-body p {
  font-size: 10px;
  color: red;
}

.organisation-from-sub-body button {
  height: 25px;
  width: 25px;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 5px;
  background-color: white;
}

.organisation-from-sub-body button img {
  width: 100%;
  height: 100%;
}

.organisation-from-close {
  width: 26px;
  height: 26px;
  margin-top: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.organisation-from-close img {
  width: 100%;
  height: 100%;
}

.organisation-toggle-btn-body {
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
  justify-content: start;
  transition: 0.3s;
  border-radius: 10px;
  background-color: #e6ebff;
}

.organisation-toggle-edit-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  color: #3d65fa;
  border: none;
  text-decoration: underline;
}

.organisation-toggle-edit-btn-sub-box {
  display: flex;
  align-items: center;
  justify-content: end;
  color: #3d65fa;
  border: none;
  cursor: pointer;
  text-decoration: underline;
}

.organisation-push-toggle {
  display: flex;
  align-items: center;
  gap: 10px;
}

.organisation-toggle-switch {
  position: relative;
  display: block;
  width: 40px;
  height: 19px;
}

.organisation-toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.organisation-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  border-radius: 34px;
  transition: 0.4s;
}

.organisation-slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 2px;
  background-color: #e9e9e9;
  border-radius: 50%;
  transition: 0.4s;
}

.organisation-toggle-switch input:checked + .organisation-slider {
  background-color: #3d65fa;
}

.organisation-toggle-switch input:checked + .organisation-slider:before {
  background-color: white;
  transform: translateX(19px);
}

.organisation-toggle-sumbit-btn-body {
  width: 100%;
  display: flex;
  gap: 25px;
  justify-content: center;
  margin-top: 10px;
  transition: 0.3s;
}

.organisation-toggle-sumbit-btn-body button {
  width: 96px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.organisation-toggle-cancel-btn {
  color: #475569;
  background-color: white;
}

.organisation-toggle-sumbit-btn {
  background-color: #3d65fa;
  color: white;
}
/* Organisation Style End*/

/* WhatsappAccount Style Start */
.whatsapp-account-body {
  width: 100%;
  height: 100%;
  padding: 20px;
}

.whatsapp-account-sub-body {
  width: 100%;
  height: 100%;
}

.whatsapp-account-title {
  font-size: 16px;
  color: #000000;
  text-transform: uppercase;
  font-family: "puvi-semi-bold";
}

.whatsapp-account-content-body {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.whatsapp-account-content-title {
  font-size: 16px;
  margin-bottom: 23px;
}

.whatsapp-account-content-sub-box {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: start;
  font-size: 14px;
  margin-bottom: 17px;
}

.whatsapp-account-content-label {
  font-size: 14px;
  min-width: 308px;
  font-family: "puvi-medium";
}

.whatsapp-account-content-value {
  font-size: 14px;
  color: #475569;
  font-family: "puvi-medium";
}

.status-text {
  text-transform: capitalize;
}

/* WhatsappAccount Style End */

/* onboarding css start */

.chat-automation-form {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.onboarding-form {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.onboarding-form-sub-box {
  height: 100%;
  overflow: hidden;
}

.onboarding-form-sub-body {
  height: calc(100% - 34px);
  overflow-y: auto;
}

.onboarding-form-sub-body::-webkit-scrollbar {
  display: none;
}

.chat-automation-body,
.onboarding-body {
  height: 100%;
}

.chat-automation-list-body {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: white;
}

.onboarding-form-box {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.new-onboard-btn-div-body {
  width: 100%;
  height: 100%;
  padding: 20px;
  padding-top: 0px;
}

.new-onboard-btn-div {
  width: 100%;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.new-onboard-btn-div button {
  border: none;
  color: white;
  font-size: 16px;
  padding: 7px 25px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: center;
  background-color: #3d65fa;
  font-family: "puvi-medium";
}

.new-onboard-btn-div button span {
  font-size: 20px;
  font-family: "puvi-medium";
}

.chat-automation-table-body {
  width: 100%;
  height: calc(100% - 74px);
  padding: 26px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: start;
  overflow-x: auto;
  overflow-y: auto;
}

.chat-automation-table-body::-webkit-scrollbar {
  display: none;
}

.chat-automation-con-box {
  width: 334px;
  height: 63px;
  color: #000000;
  text-indent: 15px;
  font-family: "puvi-medium";
  border-radius: 10px;
  align-content: center;
  border: 1px solid #e9e9e9;
  cursor: pointer;
  box-shadow: rgba(100, 100, 100, 0.05) 0px 2px 5px 0px;
}

.chat-automation-list-new-form-body {
  width: 100%;
  height: auto;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-automation-list-title {
  font-size: 16px;
  color: #000000;
  text-transform: uppercase;
  font-family: "puvi-medium";
}

.chat-automation-list-new-form {
  margin-top: 0px;
  padding: 8px 22px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  background-color: #3d65fa;
}

.chat-automation-list-new-form img {
  width: 18px;
  height: 18px;
}

.chat-automation-list-new-form div {
  color: white;
  font-size: 13px;
  margin-top: 2px;
  font-family: "puvi-semi-bold";
  font-weight: 400;
}

.chat-automation-list.selected {
  box-shadow: none;
  background-color: #f0f7ff;
}

.onboarding-form-close {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.onboarding-form-close-btn {
  padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.04);
}

.new-onboarding-form-button-body {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-onboarding-form-button {
  border: none;
  color: white;
  cursor: pointer;
  font-size: 13px;
  padding: 10px 22px;
  border-radius: 4px;
  background-color: #434ee1;
  font-family: "puvi-semi-bold";
}

.onboarding-form-empty-body {
  width: 100%;
  height: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.script-config-page {
  width: 100%;
}

.chat-auto-label-box {
  width: 100%;
  height: auto;
  margin-top: 32px;
}

.chat-auto-label-box1 {
  width: 100%;
  display: flex;
  gap: 37px;
}

.chat-auto-label-box2 {
  width: 100%;
  margin-top: 32px;
}

.chat-auto-label-div {
  width: 329px;
}

.chat-auto-label-div label {
  font-size: 16px;
  color: #475569;
  font-size: "puvi-medium";
}

.chat-auto-label-div input {
  width: 100%;
  height: 44px;
  outline: none;
  color: #202224;
  font-size: 14px;
  margin-top: 14px;
  text-indent: 16px;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
}

.chat-auto-label-div .error-text {
  color: red;
  font-size: 12px;
  display: block;
}

.chat-auto-label-box2-detail-text {
  font-size: 14px;
  color: #202224;
  margin-top: 10px;
}

.onboarding-form-div {
  width: 100%;
  margin-top: 14px;
}

.questions-container {
  width: 100%;
  margin-top: 42px;
}

.question-wrapper label {
  font-size: 14px;
  color: #475569;
  font-family: "puvi-medium";
}

.question-wrapper-input-box {
  width: 258px;
  height: 94px;
}

.question-wrapper-selection-box {
  width: 191px;
}

.onboarding-form-div .tool-template-dd-body {
  margin-top: 14px;
}
.question-wrapper-selection-box .tool-template-dd-body {
  margin-top: 14px;
}

.onboarding-form-div label {
  display: block;
  font-size: 16px;
  color: #475569;
  font-family: "puvi-medium";
}

.onboarding-form-div select {
  width: 330px;
  height: 44px;
  padding-left: 10px;
  margin-top: 14px;
  border-radius: 10px;
  color: #202224;
  outline: none;
  background-color: white;
  border: 1px solid #e9e9e9;
}

.onboarding-form-div .input {
  width: 330px;
  height: 131px;
}

.onboarding-form-div .input {
  padding: 16px 16px;
}

.onboarding-form-div textarea {
  width: 330px;
  height: 100px;
  padding: 8px;
  outline: none;
  resize: none;
  border-radius: 10px;
  margin-top: 13px;
  border: 1px solid #e9e9e9;
  background-color: #ffffff;
}

.onboarding-form-div textarea::placeholder {
  color: #aaa;
  font-style: italic;
}

.onboarding-form-div .button-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.onboarding-form-div .button-container button {
  padding: 8px 12px;
  border: 1px solid #000ba1;
  background-color: #fafcff;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
}

.onboarding-form-div .buttons {
  width: 100%;
  height: 100%;
  margin-top: 22px;
  display: flex;
  gap: 5px;
  align-items: start;
  justify-content: end;
}

.onboarding-form-div .buttons button {
  min-width: 96px;
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: start;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #b0daff;
  background-color: #fafcff;
  cursor: pointer;
}

.onboarding-form-div .buttons button img {
  width: 18px;
  height: 18px;
}

.onboarding-form-add-btn {
  width: 100%;
  height: 49px;
  color: #3d65fa;
  border-radius: 10px;
  border: none;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background-color: #e6ebff;
  cursor: pointer;
}

.onboarding-option-button-div {
  width: auto;
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: start;
  margin-top: 22px;
}

.onboarding-option-button-div button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: white;
  cursor: pointer;
}

.onboarding-option-button-div button img {
  width: 26px;
  height: 26px;
}

.onboarding-option-add-button {
  color: #0086ff;
}

.onboarding-option-remove-button {
  color: #c73f33;
}

.onboarding-form-button-div {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.onboarding-form-title {
  color: #000000;
  font-size: 16px;
  text-transform: uppercase;
  font-family: "puvi-medium";
}

.onboarding-form-button-box {
  display: flex;
  gap: 9px;
}

.onboarding-form-button-box button {
  width: 97px;
  height: 30px;
  border: none;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
  border-radius: 4px;
}

.onboarding-form-save-button {
  color: white;
  background-color: #3d65fa;
}

.onboarding-form-close-button {
  color: #475569;
  background-color: #e9e9e9;
}

.question-wrapper {
  width: 100%;
  min-height: 185px;
  padding: 22px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: start;
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
}

.onboarding-form-remove-btn {
  position: absolute;
  top: 11px;
  right: 11px;
  width: 13px;
  height: 13px;
}

.onboarding-form-remove-btn img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.question-options-container {
  width: 467px;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.question-option {
  display: flex;
  gap: 14px;
  align-items: start;
}

.question-option-sub-box {
  display: flex;
  gap: 13px;
  align-items: center;
  position: relative;
}

.question-option-sub-box .error {
  left: 45px;
}

.question-option-sub-box label {
  font-size: 12px;
  color: #969292;
}

.question-option-sub-box input {
  width: 167px;
  height: 44px;
  outline: none;
  color: #202224;
  text-indent: 14px;
  margin-top: 15px;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
}

.template-text-area-body {
  width: 100%;
  height: auto;
  position: relative;
}

.template-text-area-body .input {
  margin-top: 14px;
}

.template-text-area {
  width: 258px;
  height: 94px;
  outline: none;
  resize: none;
  border-radius: 10px;
  margin-top: 14px;
  padding: 16px;
  border: 1px solid #e9e9e9;
}

.template-text-area-dropdown {
  width: 100px;
  max-width: 300px;
  position: absolute;
  top: 100%;
  border-radius: 6px;
  background: white;
  border: 1px solid #e9e9e9;
  z-index: 1;
}

.onboarding-dropdown {
  position: absolute;
  background: #fafcff;
  border: 1px solid #b0daff;
  z-index: 10;
  width: 200px;
}

.dropdown-item {
  padding: 10px;
  font-size: 14px;
  color: #475569;
  cursor: pointer;
}

.dropdown-item:hover {
  background: #f0f0f0;
}
/* onboarding css end */

/* integrations styles start */
.integration-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 20px;
  background-color: #fff;
}

.integration-loading-container {
  width: 100%;
  height: 100%;
}

.integration-title {
  width: 100%;
  height: 70px;
  font-size: 16px;
  color: #000000;
  padding-left: 20px;
  align-content: center;
  font-family: "puvi-medium";
  text-transform: uppercase;
}

.integration-content {
  width: 100%;
  height: calc(100% - 70px);
  text-align: center;
}

.integration-status {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #00796b;
}

.integration-options {
  margin-top: 47px;
  padding-left: 20px;
}

.integration-prompt {
  font-size: 20px;
  color: #000000;
  text-align: left;
  margin-bottom: 14px;
}

.integration-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
}

.integration-option {
  width: 262px;
  height: 313px;
  border-radius: 10px;
  padding: 0px 18px;
  border: 1px solid #e9e9e9;
}

.integration-option-banner {
  width: 100%;
  height: 74px;
  margin-top: 27px;
  object-fit: contain;
}

.integration-option-title {
  font-size: 20px;
  color: #202224;
  text-align: left;
  padding-top: 19px;
  font-family: "puvi-medium";
}

.integration-option-title2 {
  height: 52px;
  font-size: 20px;
  margin-top: 25px;
  color: #202224;
  text-align: left;
  font-family: "puvi-medium";
}

.integration-option-content {
  height: 95px;
  font-size: 12px;
  margin-top: 12px;
  color: #475569;
  text-align: left;
}

.integration-btn {
  width: 224px;
  height: 40px;
  border: none;
  margin-top: 4px;
  border-radius: 6px;
  color: #3d65fa;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
  background-color: #e6ebff;
}

.revoke-btn {
  width: 224px;
  height: 40px;
  border: none;
  margin-top: 34px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
  color: #e17375;
  background-color: #ffe6eb;
}

.copy-key-btn-body {
  display: flex;
  align-self: center;
  position: relative;
}

.copy-key-text-boy {
  text-align: center;
  position: absolute;
  top: 25px;
  right: -20px;
  padding: 5px;
  border-radius: 10px;
  font-size: 12px;
  color: #475569;
  border: 1px solid #e6ebff;
  background-color: white;
}

/* .copy-key-text-boy::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -3px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #3d65fa transparent;
} */

.generate-key-btn {
  padding: 5px;
  border: none;
  color: white;
  cursor: pointer;
  margin-left: 5px;
  border-radius: 5px;
  background-color: #3d65fa;
}

.copy-key-btn {
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.auto-push-toggle {
  margin-top: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toggle-switch {
  position: relative;
  display: block;
  width: 40px;
  height: 19px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e9e9e9;
  border-radius: 34px;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

.toggle-switch input:checked + .slider {
  background-color: #4caf50;
}

.toggle-switch input:checked + .slider:before {
  transform: translateX(20px);
}

.toggle-label-text {
  font-size: 14px;
  color: #475569;
  text-align: left;
}

.api-key-container {
  width: 100%;
  text-align: left;
  padding-left: 20px;
}

.api-key-section {
  width: auto;
  height: auto;
  display: flex;
  margin-top: 14px;
}

.api-key-display {
  max-width: 57%;
  height: 53px;
  display: flex;
  gap: 19px;
  padding-right: 20px;
  border-radius: 10px;
  background-color: #e6ebff;
}

.api-key-value {
  width: 90%;
  height: 53px;
  text-indent: 21px;
  font-size: 14px;
  color: #475569;
  align-content: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.delete-key-btn {
  border: none;
  cursor: pointer;
  margin-left: 16px;
  color: white;
  background-color: white;
}

/* integrations styles end */

/* IntegrationForm.css */
.integration-login-form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 2;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.integration-login-form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.integration-login-form label {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.integration-login-form .input-field {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 4px;
  width: 100%;
}

.integration-login-form .form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.integration-login-form .cancel-button,
.integration-login-form .login-button {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.integration-login-form .cancel-button {
  background-color: #f5f5f5;
  color: #555;
}

.integration-login-form .cancel-button:hover {
  background-color: #e0e0e0;
}

.integration-login-form .login-button {
  background-color: #007bff;
  color: #fff;
}

.integration-login-form .login-button:hover {
  background-color: #0056b3;
}

/* Background Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

/* integrations styles start */

.onboarding-details-title-box {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.onboarding-details-title-box-1 {
  display: flex;
  gap: 15px;
  align-items: center;
}

.onboarding-details-title-box-back {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid #e9e9e9;
  cursor: pointer;
}

.onboarding-details-title {
  font-size: 16px;
  color: #000000;
  text-transform: uppercase;
  font-family: "puvi-medium";
}

.onboarding-details-button-box {
  width: auto;
  height: 40px;
  display: flex;
  gap: 9px;
}

.onboarding-details-button-box button {
  width: 82px;
  height: 40px;
  cursor: pointer;
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.onboarding-details-edit-btn img {
  width: 18px;
  height: 18px;
}

.onboarding-details-delete-btn img {
  width: 16px;
  height: 16px;
  margin-right: 2px;
}

.onboarding-details-edit-btn {
  color: #6383fc;
  background-color: white;
  border: 1px solid #6383fc;
}

.onboarding-details-delete-btn {
  color: #e17375;
  background-color: white;
  border: 1px solid #e17375;
}

.onboarding-details-message-body {
  width: 100%;
  min-height: 153px;
  margin-top: 14px;
  border-radius: 10px;
  padding: 36px;
  display: flex;
  gap: 69px;
  border: 1px solid #e9e9e9;
  box-shadow: rgba(100, 100, 100, 0.05) 0px 2px 5px 0px;
}

.onboarding-details-message-box1 {
  width: 75px;
  height: 81px;
}

.onboarding-details-message-box2 {
  min-width: 517px;
  max-width: 100%;
  min-height: 81px;
}

.onboarding-details-message-box-title {
  font-size: 12px;
  color: #000000;
  font-family: "puvi-medium";
}

.onboarding-details-message-box-content {
  font-size: 14px;
  color: #475569;
  margin-top: 6px;
  word-wrap: break-word;
  font-family: "puvi-medium";
}

.onboarding-details-content-box {
  width: 100%;
  height: calc(100% - 40px);
  overflow-y: auto;
  padding-bottom: 20px;
}

.onboarding-details-content-box::-webkit-scrollbar {
  display: none;
}

.onboarding-details-content-box1 {
  width: 100%;
  height: 39px;
  margin-top: 37px;
  display: flex;
  gap: 123px;
  align-items: center;
  justify-content: start;
}

.onboarding-details-label-content {
  display: flex;
  gap: 6px;
  flex-direction: column;
}

.onboarding-details-label {
  color: #000000;
  font-size: 12px;
  font-family: "puvi-medium";
}

.onboarding-details-name {
  color: #475569;
  font-size: 16px;
  font-family: "puvi-medium";
}

.onboarding-details-content-box2 {
  margin-top: 21px;
}

.onboarding-details-name-detail {
  font-size: 14px;
  color: #202224;
  margin-top: 12px;
  font-family: "puvi-medium";
}

.onboarding-details-question-box {
  width: 100%;
  height: auto;
  margin-top: 16px;
}

.onboarding-details-question-body {
  width: 100%;
  min-height: 127px;
  margin-top: 10px;
  border-radius: 10px;
  display: flex;
  gap: 72px;
  padding: 22px;
  border: 1px solid #e9e9e9;
}

.onboarding-question-1 {
  width: 22%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.onboarding-question-2 {
  width: 18%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.onboarding-question-3 {
  width: 22%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.onboarding-question-4 {

  width: 12%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.onboarding-question-label {
  font-size: 16px;
  color: #475569;
}

.onboarding-question-value {
  font-size: 16px;
  color: #202224;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 12px;
}

.onboarding-question-1 .onboarding-question-value {
  font-size: 16px;
}

.onboarding-question-3 .onboarding-question-value {
  display: flex;
  gap: 1px;
  width: auto;
}

.onboarding-details-closeing-box {
  margin-top: 14px;
}

.onboarding-details-closeing-title {
  font-size: 16px;
  color: #475569;
  font-family: "puvi-medium";
}

.onboarding-details-closeing-content {
  width: 100%;
  height: 70px;
  padding: 20px;
  margin-top: 14px;
  border-radius: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid #e9e9e9;
}

.dropDown {
  width: 100px;
  height: 100px;
  background-color: white;
  border: 1px solid red;
}

/* widget container start */

.widget-tab-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.widget-container {
  padding: 0px 5px;
}

.widget-tab-title {
  width: 100%;
  height: 70px;
  font-size: 16px;
  color: #000000;
  padding-left: 20px;
  align-content: center;
  font-family: "puvi-medium";
  text-transform: uppercase;
}

.widget-script-title {
  margin-bottom: 5px;
}

.widget-form {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 20px;
}

.widget-form-input-body {
  width: 320px;
  margin-top: 5px;
  position: relative;
}

.widget-form-input-body label {
  font-size: 14px;
  color: #475569;
  font-family: "puvi-medium";
}

.widget-form-input-body input {
  width: 100%;
  height: 44px;
  outline: none;
  margin-top: 14px;
  text-indent: 8px;
  font-size: 12px;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
}

.widget-script-container {
  width: 100%;
  background: #fff;
  border-radius: 12px;
  padding:0px 15px;
}

.widget-edit-button {
  color: #434ee1;
  text-decoration: underline;
  font-family: "puvi-medium";
  font-size: 14px;
  cursor: pointer;
  margin-top: 15px;
  margin-left: 19px;
}

.widget-clear-button {
  color: #434ee1;
  text-decoration: underline;
  font-family: "puvi-medium";
  font-size: 14px;
  cursor: pointer;
}

.widget-script-box {
  max-width: 57%;
  height: 53px;
  display: flex;
  gap: 19px;
  padding-right: 20px;
  border-radius: 10px;
}

.widget-script {
  width: 90%;
  height: 53px;
  text-indent: 21px;
  font-size: 14px;
  color: #475569;
  align-content: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #e6ebff;
  border-radius: 10px;
}

.widget-copy-button-box {
  display: flex;
  align-self: center;
  position: relative;
}

.widget-copy-key-btn {
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.widget-copy-key-text-boy {
  text-align: center;
  position: absolute;
  top: 25px;
  right: -20px;
  padding: 5px;
  border-radius: 10px;
  font-size: 12px;
  color: #475569;
  border: 1px solid #e6ebff;
  background-color: white;
}

/* widget container end */