.template-form-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.template-form-header-section {
  width: 100%;
  height: 60px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
}

.template-header-title-section {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: start;
}

.template-header-title-section-img-body {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
  background-color: white;
  border: 1px solid #e9e9e9;
}

.template-header-title-section-img {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.template-form-header-section p {
  font-size: 16px;
  color: #000000;
  font-family: "puvi-medium";
}

.template-button-container {
  height: 30px;
  display: flex;
  gap: 9px;
  align-items: center;
  justify-content: start;
}

.template-head-button {
  width: 96px;
  height: 30px;
  border: none;
  font-size: 12px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  font-family: "puvi-medium";
}

.template-create-button {
  color: #ffffff;
  background-color: #3d65fa;
}

.template-close-button {
  color: #475569;
  background-color: #e9e9e9;
}

.main-form-section {
  width: 100%;
  height: calc(100% - 61px);
  padding-left: 60px;
  padding-bottom: 10px;
  display: flex;
}

.form-area {
  width: 100%;
  overflow-y: auto;
}

.form-area::-webkit-scrollbar {
  display: none;
}

.template-form-group-inline {
  width: 100%;
  display: flex;
  gap: 33px;
  margin-top: 24px;
}

.form-control-inline {
  width: 329px;
  height: 109px;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
}

.label {
  font-size: 16px;
  color: #475569;
  margin-bottom: 14px;
}

.template-name-input {
  width: 326px;
  height: 44px;
  outline: none;
  text-indent: 10px;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  background-color: white;
}

.select-element {
  padding-right: 10px;
  cursor: pointer;
}

.top {
  width: 300px;
  margin-left: 8px;
}

.radio-group {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.radio-group label {
  font-size: 14px;
  color: #252525;
  font-weight: 400;
}

.radio-group input {
  margin-right: 5px;
}

.content-container {
  width: 100%;
  height: auto;
  margin-top: 35px;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
}

.content-title {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  margin-left: 5px;
}

.form-content {
  width: calc(100% - 345px);
  background-color: #fff;
}

.preview-content {
  width: 345px;
  height: 100%;
  overflow-y: scroll;
  background-color: #fff;
}

.preview-content-title {
  font-size: 16px;
  color: #475569;
  font-family: "puvi-medium";
}

.preview-content-box {
  border-radius: 8px;
  overflow: hidden;
}

.template-preview-content {
  width: 100%;
  height: 100%;
  padding: 12px;
  margin-top: 15px;
  position: relative;
  background-color: #e6ebff;
  border-radius: 8px 8px 0px 0px;
}

.preview-media-content {
  width: 100%;
  height: 100%;
  max-height: 207px;
  cursor: pointer;
  text-align: center;
  align-content: center;
  border-radius: 10px;
  overflow: hidden;
}

.template-preview-media-content {
  height: 100%;
  max-height: 207px;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  align-content: center;
}

.template-preview-media-content .local-load-content-img-box img {
  width: 100%;
  height: 100%;
}

.preview-media-content-bg{
  width: 100%;
  height: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../app\ assets/media/blur.jpg");
}

.template-preview-media-content img {
  width: 322px;
  height: 207px;
  object-fit: cover;
}

.template-preview-button {
  width: 100%;
  height: 40px;
  font-size: 13px;
  margin-top: 2px;
  cursor: pointer;
  color: #3d65fa;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e6ebff;
}

.template-preview-button img {
  margin-right: 9px;
}

.template-preview-body {
  font-size: 13px;
  margin-top: 12px;
  color: #000000;
}

.template-preview-footer {
  font-size: 13px;
  margin-top: 9px;
  color: #000000;
  line-height: 20px;
  font-family: "puvi-light";
}

.template-preview-button-container {
  overflow: hidden;
  border-radius: 0px 0px 0px 8px;
}

.button-container {
  display: flex;
  justify-content: center;
  background-color: grey;
}

.create-button {
  z-index: 1;
  border: none;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  margin-top: 17px;
  padding: 8px 22px;
  margin-right: 20px;
  border-radius: 5px;
  display: flex;
  gap: 4px;
  align-items: center;
  background-color: #434ee1;
}

.close-button {
  z-index: 1;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: #030303;
  padding: 5px 22px;
  border-radius: 5px;
  margin-right: 20px;
  background-color: #dee2e7;
}

.form-content .input,
.form-content .textarea {
  width: 450px;
  font-size: 14px;
  font-weight: 400;
  color: #252525;
}

.form-header-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-header-content-sub-box {
  width: 100%;
  height: auto;
}

.form-header-content-label {
  font-size: 16px;
  color: #475569;
  font-family: "puvi-medium";
}

.form-header-content-sub-box .tool-template-dd-body {
  margin-top: 14px;
}

.template-header-var-text-body {
  width: 100%;
  display: flex;
  gap: 33px;
  align-items: start;
  margin-top: 14px;
}

.template-header-text {
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
  margin-top: 7px;
}

.form-header-content-input {
  width: 330px;
  height: 44px;
  outline: none;
  font-size: 14px;
  margin-top: 14px;
  text-indent: 10px;
  color: #202224;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
}

.form-body-content {
  margin-top: 20px;
  margin-bottom: 24px;
  position: relative;
  display: flex;
  gap: 33px;
  justify-content: space-between;
}

.template-text-area-main-box {
  position: relative;
}

.form-body-content-sub-box {
  display: flex;
  gap: 14px;
  flex-direction: column;
}

.form-body-content-sub-box label {
  font-size: 16px;
  color: #475569;
  font-family: "puvi-medium";
}

.form-body-content-textarea {
  width: 330px;
  height: 131px;
  outline: none;
  resize: none;
  padding: 10px;
  font-size: 14px;
  color: #202224;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
}

.text-area-inside-drop-down {
  max-width: 200px;
  min-height: 40px;
  height: 200px;
  overflow-y: auto;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  z-index: 1;
  background-color: white;
}

.text-area-inside-drop-down::-webkit-scrollbar {
  display: none;
}

.text-area-inside-drop-down-value {
  width: 100%;
  height: 44px;
  padding: 0px 10px;
  align-content: center;
}

.text-area-inside-drop-down-value1{
  color: #6383FC;
}

.text-area-inside-drop-down-value:hover {
  background-color: #e6ebff;
  cursor: pointer;
}


.template-varibale-label-content {
  height: 20px;
  font-size: 12px;
  margin-top: 10px;
  color: #969292;
}

.body-variable-container {
  width: 400px;
  min-height: 131px;
  margin-top: 3px;
  overflow-y: scroll;
}


.header-variable-content {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  border-radius: 10px;
}

.header-variable-content label {
  min-width: 60px;
  max-width: 90px;
  height: 44px;
  font-size: 14px;
  color: #475569;
  text-align: center;
  align-items: center;
  border-radius: 10px 0px 0px 10px;
  padding: 13px 13px;
  background-color: #e6ebff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header-variable-content input {
  width: 269px;
  height: 44px;
  padding: 10px;
  outline: none;
  font-size: 14px;
  color: #202224;
  border-radius: 0px 10px 10px 0px;
  border: 1px solid #e9e9e9;
}

.body-variable-content {
  display: flex;
  align-items: start;
  border-radius: 10px;
  max-width: 330px;
}

.body-variable-content label {
  min-width: 60px;
  max-width: 90px;
  height: 44px;
  font-size: 14px;
  color: #475569;
  text-align: center;
  align-items: center;
  border-radius: 10px 0px 0px 10px;
  padding: 13px 13px;
  background-color: #e6ebff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.body-variable-content input {
  width: 269px;
  height: 44px;
  padding: 10px;
  outline: none;
  font-size: 14px;
  color: #202224;
  border-radius: 0px 10px 10px 0px;
  border: 1px solid #e9e9e9;
}

.body-template-refers-text{
  font-size: 12px;
  margin-top: 3px;
}



.template-form-footer-content {
  width: 761px;
  position: relative;
  margin-bottom: 24px;
}

.template-form-footer-content-sub-box {
  width: 100%;
  display: flex;
  gap: 14px;
  flex-direction: column;
}

.template-form-footer-content-sub-box label {
  font-size: 16px;
  color: #475569;
  font-family: "puvi-medium";
}

.template-form-footer-input {
  width: 100%;
  height: 45px;
  outline: none;
  font-size: 14px;
  color: #202224;
  text-indent: 10px;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
}

.template-add-btn-body {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.template-add-btn-label label {
  font-size: 16px;
  color: #475569;
  font-family: "puvi-medium";
}

.template-form-new-button {
  cursor: pointer;
  font-size: 14px;
  margin-top: 17px;
  color: #3d65fa;
  border: none;
  font-family: "puvi-semi-bold";
  background-color: white;
  text-decoration: underline;
}

.example-title {
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: #3f4857;
}

.header-param-container {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.body-param-container {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.template-file-upload-label {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.file-container-title{
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: #3f4857;
  margin-top: 14px;
  text-transform:capitalize ;
}

.temp-details-file-container-title{
  margin-top: 0px;
  text-transform:capitalize ;

}

.file-container {
  width: 170px;
  height: 66px;
  display: flex;

}

.file-upload-container {
  min-width: 89px;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #e9e9e9;
  position: relative;
}

.template-form-file-preview {
  width: 89px;
  height: 66px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.template-form-file-preview img {
  width: 89px;
  height: 66px;
  object-fit: cover;
  border-radius: 10px;
}

.file-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.file-options p {
  font-size: 14px;
  color: #e17375;
  cursor: pointer;
  margin-left: 8px;
  text-decoration: underline;
}

.video-preview-body {
  width: 100%;
  height: 100%;
  max-height: 200px;
  position: relative;
}

.video-preview-media-body {
  width: 100%;
  height: 100%;
}

.video-preview-body-cover {
  width: 100%;
  height: 100%;
  max-height: 150px;
  z-index: 1;
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #00000040;
}

.video-preview-body-cover-img {
  width: 36px !important;
  height: 36px !important;
}

.video-preview-image-body {
  width: 100%;
  height: 100%;
  max-height: 150px;
  min-height: 150px;
  overflow: hidden;
  border-radius: 10px;
}

.video-preview-image {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.no-preview-div {
  width: 100%;
  height: 100%;
  overflow: "hidden";
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-preview-div div {
  width: 100%;
  height: 100%;
  overflow: "hidden";
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.no-preview-div div img {
  width: 25%;
  height: 25%;
}

.file-info-container {
  display: flex;
  align-items: center;
  height: 25%;
  padding: 8px;
  background-color: #d6deff;
  border-radius: 0px 0px 10px 10px;
}

.non-text-icon {
  margin-right: 8px;
}

.non-text-icon img {
  width: 30px;
  height: 30px;
}

.content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-details {
  display: flex;
  flex-direction: column;
}

.file-name {
  font-size: 14px;
  color: #202224;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: "puvi-medium";
}

.file-size {
  height: 20px;
  font-size: 12px;
  align-content: center;
  color: #475569;
}

.no-preview-div div p {
  font-size: 10px;
  text-align: center;
  /* padding: 5px; */
}

.button-variable-content {
  /* height: 60px; */
  display: flex;
  align-items: end;
  justify-content: center;
  margin-top: 13px;
  border-radius: 10px;
}

.button-variable-content label {
  min-width: 60px;
  max-width: 90px;
  height: 45px;
  font-size: 14px;
  color: #475569;
  padding: 13px 13px;
  border-radius: 10px 0px 0px 10px;
  background-color: #e6ebff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button-variable-content input {
  width: 167px;
  height: 45px;
  outline: none;
  text-indent: 10px;
  border-radius: 0px 10px 10px 0px;
  border: 1px solid #e9e9e9;
}



.form-button-container button {
  margin-top: 5px;
  margin-left: 10px;
  margin-bottom: 20px;
}

.form-button-content {
  width: 98%;
  display: flex;
  justify-content: start;
  gap: 5px;
  margin-left: 2px;
}

.reorder-icon-sub-body{
  height: 30px;
  margin-top: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reorder-icon {
  width: 22px;
  height: 20px;
  cursor: move;
  opacity: 1;
  padding-left: 2px;
}

.template-buttons-delete-btn-body {
  margin-left: 5px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.template-buttons-delete-btn-body img {
  width: 18px;
  height: 20px;
}

.button-dropdown-select {
  width: 176px;
  height: 45px;
  border-radius: 10px;
  padding-left: 16px;
  outline: none;
  background-color: white;
  border: 1px solid #e9e9e9;
}

.button-input {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.template-button-text-div {
  display: flex;
  flex-direction: column;
}

.template-button-value-div {
  display: flex;
  flex-direction: column;
}

.template-button-param-div {
  display: flex;
  flex-direction: column;
}

.template-btn-label-input-box {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: relative;
}

.template-btn-label-input-box label{
  font-size: 12px;
  height: 20px;
  color: #969292;
  position: absolute;
  top:-2px;
  left: 3px;
}

.template-btn-label-input {
  width: 210px;
  height: 45px;
  outline: none;
  text-indent: 10px;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
}

.template-btn-copy-code-input {
  width: 210px;
  height: 45px;
  outline: none;
  text-indent: 10px;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
}

.template-btn-var-label-input-box {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: relative;
}

.template-btn-var-label-input-box label{
  height: 20px;
  font-size: 12px;
  color: #969292;
  position: absolute;
  top: -2px;
  left: 5px;
  text-transform: capitalize;
}

.template-btn-var-label-input {
  width: 246px;
  height: 45px;
  outline: none;
  text-indent: 10px;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
}

.error {
  color: red;
  font-size: 12px;
  font-weight: 400;
  font-family: "puvi-regular";
  width: 100%;
}

.phone-error {
  margin-left: 70px;
}

.error-input {
  border: 1px solid red !important;
}

/* For Firefox */
*::-webkit-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* For Edge, Internet Explorer */
body {
  -ms-overflow-style: none; /* IE and Edge */
}

body::-webkit-scrollbar {
  display: none; /* For Safari and Chrome */
}

.template-load-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* phone button */
.template-phone-field-container {
  position: relative;
}

.template-phone-field-container-label-box {
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  z-index: 2;
  border-radius: 10px;
}

.template-phone-country-code-box {
  height: 44px;
  cursor: pointer;
  padding-left: 8px;
  padding-right: 5px;
  z-index: 2;
  display: flex;
  align-items: center;
  border-radius: 10px 0px 0px 10px;
  background-color: #e6ebff;
}

.template-phone-country-code {
  font-size: 14px;
  color: #475569;
  font-family: "puvi-medium";
}

.template-phone-dropdown-arrow {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.template-phone-input {
  width: 269px;
  height: 44px;
  outline: none;
  border: none;
  font-size: 14px;
  color: #202224;
  border-radius: 0px 10px 10px 0px;
  border: 1px solid #e9e9e9;
  font-family: "puvi-medium";
  z-index: 2;
  text-indent: 10px;
}

.template-country-code-dropdown {
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 1;
  padding-top: 5px;
  background-color: white;
  border: 1px solid #e9e9e9;
  border-radius: 0px 0px 10px 10px;
  border-top: none;
}

.template-country-code-dropdown::-webkit-scrollbar {
  display: none;
}

.template-dropdown-search-input-box {
  width: 100%;
  height: 44px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.template-dropdown-search-input {
  width: 98%;
  height: 100%;
  outline: none;
  text-indent: 10px;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
}

.template-dropdown-search-input::placeholder {
  font-size: 14px;
}

.template-country-code-dropdown-list-box {
  width: 100%;
  height: 195px;
  margin-top: 3px;
  overflow-y: scroll;
}

.template-country-code-container {
  height: 39px;
  padding-left: 16px;
  padding-right: 11px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.template-country-code-container:hover {
  background-color: #e6ebff;
}

.template-country-country-code {
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
}

.template-selected-tick {
  width: 24px;
  height: 24px;
}

.countries-not-found {
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}
