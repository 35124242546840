.alert-message-body {
  width: 100vw;
  min-height: 40px;
  padding: 5px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10vh;
  overflow: hidden;

}

.alert-msg-sub-body {
  width: auto;
  max-width: 339px;
  min-height: 70px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border-radius: 12px;
  transform: translateY(-150px);
  transition: 0.5s;
  transition-timing-function: ease;
  z-index: 4;
  padding: 15px 0px;
  padding-left: 20px;
  padding-right: 26px;
  position: relative;
}

.information-body {
  max-width: 600px;
  height: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 26px;
  color: #474747;
  background-color: #F2FCFF;
  border: 1px solid #73BBE1;
  z-index: 4;
  border-radius: 12px;
}

.information-sub-body{
  display: flex;
  justify-content: start;
  gap: 5px;
  flex-direction: column;
  transition: 0.3s;
}

.alert-list-body
{
  overflow-y: scroll;
  width: 100%;
  max-height: 400px;
}

.push-list-value{
  margin-top: 3px;
}

.success-body {
  color: #474747;
  background-color: #e5f6e8;
}

.error-body {
  color: #474747;
  border: 1px solid #E17375;
  background-color: #fff2f2;
}

.alert-msg-down {
  transform: translateY(0px);
}

.alert-msg-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert-msg-icon img {
  min-width: 20px;
  min-height: 20px;
}

.error-alert-msg-icon img{
 min-width: 24px;
 min-height: 24px;
}

.alert-msg-title{
  font-size: 14px;
  color: #202224;
  font-family: "puvi-bold";
}

.alert-msg-content {
  width: auto;
  font-size: 12px;
  margin-top: 5px;
  color: #202224;
  text-align: start;
  align-content: center;
  word-wrap: break-word;
  font-family: "puvi-regular";
  line-height: 15px;
}

.alert-msg-navigate-text
{
  cursor: pointer;
  color: #3D65FA;
  text-decoration: underline;
  font-family: "puvi-bold";
}

.alert-msg-cancel {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.alert-msg-cancel img {
  width: 100%;
  height: 100%;
  min-width: 18px;
  min-height: 18px;
}
