@font-face {
  font-family: "puvi-regular";
  src: url("./assets/fonts/Zoho Puvi/Zoho Puvi/OpenType-PS/Zoho Puvi Regular.otf");
}

@font-face {
  font-family: "puvi-medium";
  src: url("./assets/fonts/Zoho Puvi/Zoho Puvi/OpenType-PS/Zoho Puvi Medium.otf");
}

@font-face {
  font-family: "puvi-bold";
  src: url("./assets/fonts/Zoho Puvi/Zoho Puvi/OpenType-PS/Zoho Puvi Bold.otf");
}

@font-face {
  font-family: "puvi-semi-bold";
  src: url("./assets/fonts/Zoho Puvi/Zoho Puvi/OpenType-PS/Zoho Puvi Semibold.otf");
}

@font-face {
  font-family: "puvi-light";
  src: url("./assets/fonts/Zoho Puvi/Zoho Puvi/OpenType-PS/Zoho Puvi Light.otf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "puvi-regular";
}

body::-webkit-scrollbar {
  display: none;
}

.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

/* Header compount Style Start */
.header {
  width: 100%;
  height: 49px;
  padding-left: 14px;
  padding-right: 24px;
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-bottom: 1px solid #e9e9e9;
}

@media (min-width: 480px) and (max-width: 1155px) {
  .header {
    justify-content: center;
  }
}

.brand {
  display: flex;
  align-items: center;
}

.logo {
  width: 123px;
  height: 30px;
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: default;
}

.logo img {
  width: 33px;
  height: 30px;
  cursor: default;
}

.logotext {
  width: 85px;
  height: 23px;
  font-size: 20px;
  line-height: 22.94px;
  color: #0086ff;
  font-family: "puvi-bold";
}

.navbar {
  min-height: 40px;
  margin-left: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.nav-child {
  height: 40px;
  align-content: center;
}

.navbar .nav-child:nth-child(2) {
  position: relative;
}

.header-notification-badge {
  position: absolute;
  left: 70%;
  top: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #0086ff;
  font-size: 8px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "puvi-semi-bold";
}

.icon {
  cursor: pointer;
}

.nav {
  font-size: 14px;
  color: #000000bf;
  font-family: "puvi-medium";
  padding: 12px 18px 12px 18px;
  line-height: 16px;
  text-decoration: none;
}

.nav.clicked {
  color: #3d65fa;
  border-bottom: 3px solid #3d65fa;
  border-radius: 1px;
}

.nav.disabled {
  pointer-events: none;
}

.header-acc-details-main-box {
  height: 32px;
  position: relative;
}

.account {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 20px;
  justify-content: end;
  align-items: center;
}

.header-profile-div {
  width: 34px;
  height: 34px;
  text-align: center;
  align-content: center;
  border-radius: 50%;
  border: 1px solid #3d65fa;
  color: #3d65fa;
  font-size: 12px;
  font-family: "puvi-semi-bold";
  cursor: pointer;
  background-color: #f0f0f0;
  z-index: 5;
}

.header-drop-down-box {
  min-width: 207px;
  min-height: 100px;
  position: absolute;
  top: 45px;
  right: 0px;
  z-index: 5;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f0f0;
  border: 1px solid #e9e9e9;
  border-radius: 10px 3px 10px 10px;
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.3);
}

.header-drop-down-box::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 90%;
  margin-left: -5px;
  border-width: 9px;
  border-style: solid;
  border-color: transparent transparent #f0f0f0 transparent;
}

.header-drop-down-box::before {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 90%;
  margin-left: -6px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #e9e9e9 transparent;
}

.header-details-box {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.header-details-letter {
  width: 65px;
  height: 65px;
  font-size: 18px;
  border-radius: 50%;
  text-align: center;
  align-content: center;
  color: #3d65fa;
  background-color: #f0f0f0;
  border: 2px solid #3d65fa;
}

.header-details-name {
  font-size: 14px;
  color: #475569;
  margin-top: 13px;
  font-family: "puvi-semi-bold";
}

.header-details-mail {
  font-size: 12px;
  color: #475569;
  margin-top: 4px;
}

.header-details-btn-box {
  margin-top: 14px;
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.header-details-btn-box button {
  height: 30px;
  padding: 6px 12px;
  font-size: 12px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #f0f0f0;
}

.password-btn {
  color: #3d65fa;
  height: 10px;
  border: none;
  text-decoration: underline;
  /* border: 1px solid #3D65FA; */
  background-color: white;
}

.logout-btn {
  color: #e17375;
  border: 1px solid #e17375;
  background-color: white;
}

/* Header compount Style End */

/* ContactList compount Style Start */
.contacts-container {
  /* width: 28%; */
  width: 25%;
  height: 100%;
  border-right: 1px solid #e9e9e9;
}

.contacts-container::-webkit-scrollbar {
  display: none;
}

.head-container {
  width: 100%;
  height: 72px;
  z-index: 1;
  transition: 0.3s;
  padding: 13px 15px;
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: #ffffff;
  border-bottom: 1px solid #dee2e4;
}

.contactlist-sidebar-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.contactlist-sidebar-part1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.parent-dd {
  width: 64.5%;
  height: 44px;
  position: relative;
}

.filter {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.filter-sub-box {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: relative;
  padding-left: 16px;
  padding-right: 7px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e9e9e9;
  background-color: white;
}

.text {
  width: 80%;
  max-width: 141px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.text p {
  font-size: 14px;
  color: #393c3f;
  font-family: "puvi-medium";
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.down {
  width: 20px;
  height: 20px;
  transition: 0.3s;
  transform: rotate(0deg);
}

.up {
  width: 20px;
  height: 20px;
  transition: 0.3s;
  transform: rotate(180deg);
}

.contact-dropdown-content {
  width: 99.5%;
  height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 38px;
  left: 0px;
  z-index: 1;
  border: 1px solid #e9e9e9;
  border-radius: 0px 0px 10px 10px;
  background-color: #ffffff;
}

.contact-dropdown-content::-webkit-scrollbar {
  display: none;
}

.contact-dropdown-item {
  width: 100%;
  height: 40px;
  font-size: 16px;
  padding-left: 16px;
  padding-right: 11px;
  color: #333333;
  display: flex;
  cursor: pointer;
  font-family: "puvi-medium";
  align-items: center;
  justify-content: space-between;
}

.contact-dropdown-item:hover {
  background-color: #e5e4e7;
}

.header-nav-options {
  height: 44px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.read-filter {
  width: 44px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  cursor: pointer;
}

.filtericon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.unread-filtericon {
  width: 24px;
  height: 24px;
}

.chatlist-search-box {
  width: 44px;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
}

.chatlist-search-icon {
  width: 24px;
  height: 24px;
  object-fit: cover;
}

.head-container-search-input-box {
  width: 100%;
  position: relative;
}

.head-container-search-input {
  width: 100%;
  height: 44px;
  padding: 0px 10px;
  padding-right: 10%;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  outline: none;
}

.head-container-search-input-close {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 20%;
  right: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.2s;
}

.head-container-search-input-close img {
  width: 80%;
  height: 80%;
}

.head-container-search-input-close:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.contacts-list {
  width: 100%;
  /* height: 300px !important; */
  overflow-y: auto;
  background-color: #ffffff;
}

.contacts-list::-webkit-scrollbar {
  display: none;
}

.featch-filter-loading {
  width: 100%;
  padding-top: 10px;
}
/* ContactList compount Style End */

/* Contact compount Style Start */
.contact {
  width: 100%;
  height: 83px;
  cursor: pointer;
  padding: 30px 17px;
  display: flex;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #edf1f3;
}

.sub-contact {
  width: 92%;
  height: 48px;
}

.profile {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
}

.profile-image-letter-div {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.profile-image-letter {
  font-size: 14px;
  font-family: "puvi-medium";
}

.profile-details {
  margin-left: 10px;
}

.user-name {
  width: 100%;
  height: 22px;
  font-size: 16px;
  color: #1e293b;
  text-align: start;
  font-family: "puvi-semi-bold";
}

.phone-number {
  height: 20px;
  margin-top: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #475569;
  line-height: 20px;
}

.notifications {
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.time-stamp {
  white-space: nowrap;
  font-size: 12px;
  color: #94a3b8;
  line-height: 20px;
  font-family: "puvi-regular";
}

.badge {
  width: 20px;
  height: 20px;
  margin-top: 4px;
  text-align: center;
  position: relative;
  border-radius: 50%;
  background-color: #3d65fa;
}

.badge span {
  font-size: 12px;
  color: #ffffff;
  font-family: "puvi-bold";
}

.badge-selected {
  width: 20px;
  height: 20px;
  margin-top: 4px;
  text-align: center;
  position: relative;
  border-radius: 50%;
  background-color: #ffffff;
}

.badge-selected span {
  font-size: 12px;
  color: #3d65fa;
  font-family: "puvi-bold";
}

.selected {
  color: #ffffff;
  background-color: #3d65fa;
}
/* Contact compount Style End */

/* Chats compount Style Start */
.main {
  width: 100%;
  height: calc(100vh - 49px);
  display: flex;
  position: relative;
}

.initial {
  width: 75%;
  height: 100%;
  font-size: 14px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.75);
  background-color: #ffffff;
  border-left: 1px solid #edf1f3;
}

.chat-details-toggle-button {
  width: 17px;
  height: 66px;
  cursor: pointer;
  position: absolute;
  top: 45%;
  right: 22.1%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 8px 0px 0px 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 2px;
}

.end {
  right: 0;
}

.chat-details-toggle-button img {
  width: 16px;
  height: 16px;
}
/* Chats compount Style End */

/* ChatContainer compount Style Start */
.chat-container {
  width: 75%;
  height: 100%;
  display: flex;
}

.header-chat-body {
  height: 100%;
  border-left: none;
  border-top: none;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-right: 1px solid #e9e9e9;
  border-left: 1px solid #e9e9e9;
}

.chat-header {
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
}

.header-left {
  max-width: 40%;
  height: 44px;
  display: flex;
  gap: 12px;
  align-items: center;
  margin-left: 17px;
  cursor: pointer;
}

.chat-inside-profile-div {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f0f7ff;
  border: 0.5px solid #0088ffc7;
  cursor: pointer;
}

.chat-inside-profile-letter {
  font-size: 12px;
  font-family: "puvi-medium";
}

.chat-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.chat-user-name-body {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
}

.chat-user-name {
  font-size: 16px;
  color: #1e293b;
  line-height: 22px;
  font-family: "puvi-bold";
}

.chat-user-info-img-body {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.chat-user-info-img {
  width: 22px;
  height: 22px;
  object-fit: contain;
}

.header-right {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 60%;
  padding: 18px;
}

.header-right-all-icons {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.chat-header-expairy-timer {
  height: 48px;
  border-radius: 4px;
  padding: 4px 10px;
  background-color: #ffeeee;
  position: relative;
}

.chat-header-expairy-content-box {
  width: 82px;
  height: 20px;
  font-size: 10px;
  display: flex;
  align-items: center;
  color: #969292;
}

.chat-header-expairy-time-box {
  height: 21px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.expairy-time {
  width: 70px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: start;
  color: #e17375;
  font-family: "puvi-semi-bold";
}

.expairy-time-units {
  width: 22px;
  height: 16px;
  color: #e17375;
  font-family: "puvi-semi-bold";
}

.chat-header-expairy-timer-details-icon {
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
}

.chat-header-expairy-timer-details-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.showing-expiry-time {
  width: 125px;
  height: 22px;
  position: absolute;
  top: 100%;
  right: 4px;
  opacity: 0;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  z-index: 1;
  color: #3f4857 !important;
  background-color: white;
  border: 1px solid #edf1f3;
}

.showing-expiry-time::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  right: 7%;
  margin-left: -5px;
  border-width: 4px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.showing-expiry-time::before {
  content: " ";
  position: absolute;
  bottom: 100%;
  right: 6.5%;
  margin-left: -6px;
  border-width: 4.5px;
  border-style: solid;
  border-color: transparent transparent #edf1f3 transparent;
}

.chat-header-expairy-timer-details-icon img:hover + .showing-expiry-time {
  opacity: 1;
  cursor: pointer;
}

/* chat ASSIGNEE drop down - START */
.chat-header-assignee-body {
  max-width: 203px;
  min-height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 18px;
  margin-left: 18px;
  border-left: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
}

.chat-header-assignee-content-box {
  width: 100%;
  height: 100%;
}

.chat-header-assignee-label {
  font-size: 10px;
  height: 20px;
  color: #969292;
}

.chat-header-assignee-label-box {
  display: flex;
  gap: 6px;
  align-items: start;
  justify-content: start;
}

.chat-header-assignee {
  font-size: 12px;
  color: #475569;
  height: 15px;
  font-family: "puvi-semi-bold";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  text-transform: capitalize;
}

.chat-header-assignee-label-img {
  width: 18px;
  height: 18px;
  margin-top: -2px;
  cursor: pointer;
  transition: 0.3s;
}

/* chat STATUS drop down - START */
.chat-header-status-body {
  padding-left: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-header-status-content-box {
  width: 100%;
  height: 100%;
  position: relative;
}

.chat-header-status-label {
  font-size: 10px;
  height: 20px;
  color: #969292;
}

.chat-header-status-label-box {
  display: flex;
  gap: 6px;
  align-items: start;
  justify-content: start;
}

.chat-header-status {
  font-size: 12px;
  color: #475569;
  height: 15px;
  font-family: "puvi-semi-bold";
  text-transform: capitalize;
}

.chat-header-status-label-img {
  width: 18px;
  height: 18px;
  margin-top: -2px;
  cursor: pointer;
  transition: 0.3s;
}

.chat-header-status-label-edit-img {
  opacity: 0;
}

.chat-header-status-body:hover .chat-header-status-label-edit-img {
  opacity: 1;
}
/* End */

/*Templates Style Start  */
.msg-page-template-table-body {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: #000000b2;
  z-index: 3;
  display: grid;
  place-items: center;
}

.msg-page-template-table-sub-body {
  width: 70%;
  max-width: 956px;
  height: 90vh;
  padding: 20px;
  padding-top: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
}

.msg-page-template-table-box {
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.msg-page-template-table-box::-webkit-scrollbar {
  display: none;
}

.msg-page-template-table {
  width: 100%;
  border-collapse: collapse;
}

.msg-page-template-table-th {
  text-align: left;
  height: 57px;
  text-indent: 10px;
  background-color: #e6ebff;
}

.msg-page-template-table-tr {
  cursor: pointer;
  text-align: left;
  height: 56px;
  border-bottom: 1px solid #ddd;
}

.msg-page-template-table-td {
  padding-left: 10px;
}

.msg-page-template-close-btn-body {
  width: 100%;
  height: 50px;
  padding: 0px 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: 1px solid #e9e9e9;
}

.msg-page-template-title {
  font-size: 16px;
  color: #000000;
  font-family: "puvi-medium";
}

.msg-page-template-close-btn {
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
}
/*Templates Style End  */

.chat-total-body {
  width: 100%;
  height: calc(100% - 72px);
  position: relative;
}

.chat-area {
  width: 100%;
  height: 80%;
  height: calc(100% - 50px);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  background-color: white;
}

.chat-area::-webkit-scrollbar {
  display: block;
  width: 4px;
}

.chat-area::-webkit-scrollbar-track {
  background-color: white;
}

.chat-area::-webkit-scrollbar-thumb {
  background-color: #d8d2c2;
  border-radius: 2px;
}

.chat-area-msg-loading-div {
  width: 100%;
  height: auto;
  padding: 20px 0px;
  position: sticky;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.date {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.date-span {
  display: grid;
  place-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.date span {
  font-size: 12px;
  color: #94a3b8;
  font-family: "puvi-regular";
}

/*  */

.loadmore-button-body {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadmore-button-sub-body {
  width: 100%;
  height: auto;
  display: flex;
  border-radius: 1px;
  margin: 10px 0px;
  align-items: center;
  justify-content: center;
}

.loadmore-button {
  width: 90%;
  padding: 5px 10px;
  border: none;
  color: #0086ff;
  border-radius: 3px;
  font-size: 11px;
  font-family: "puvi-medium";
  cursor: pointer;
  background-color: rgba(204, 195, 195, 0.212);
}

.unread-message-text {
  width: 100%;
  font-size: 10px;
  margin: 5px 0px;
  color: #0086ff;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "puvi-regular";
}

.unread-message-text::before,
.unread-message-text::after {
  content: "";
  flex: 0 0 44%;
  border-bottom: 1px solid #434ee1;
}

.unread-message-text::before {
  margin-right: 10px;
}

.unread-message-text::after {
  margin-left: 10px;
}
/* ChatContainer compount Style End */

/* Chat compount Style Start */
.chat-message-header {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: end;
  position: relative;
}

.chat-message-header-incomeing {
  width: 100%;
  height: 10px;
  display: flex;
  justify-content: start;
  position: relative;
}

.chat-message-header-outgoing {
  width: 100%;
  height: 10px;
  display: flex;
  justify-content: end;
  position: relative;
}

.chat-sender-details {
  display: flex;
  gap: 4px;
  align-items: center;
}

.chat-sender-name {
  font-size: 12px;
  font-family: "puvi-medium";
}

.chat-sender-dot {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #d9d9d9;
}

.chat-sending-time {
  color: #727489;
  font-size: 12px;
  font-family: "puvi-regular";
}

.chat-message-footer {
  display: flex;
  width: 100%;
  height: 10px;
  justify-content: flex-end;
  align-items: center;
}

.msg-stared-img {
  width: 10px;
  height: 10px;
  cursor: pointer;
  object-fit: cover;
}

.msg-unstared-img {
  width: 10px;
  height: 10px;
  cursor: pointer;
  object-fit: cover;
}

.footer-deliverd-info-box {
  width: 10px;
  height: 10px;
  cursor: pointer;
}

.footer-deliverd-info-img-body {
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-deliverd-info-box:hover .chat-info {
  display: block;
}

.chat-info {
  min-width: 186px;
  max-height: 40px;
  position: absolute;
  right: -6px;
  top: 103%;
  padding: 8px 0px;
  padding-left: 10px;
  padding-right: 6px;
  border-radius: 4px;
  font-size: 10px;
  display: none;
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 3px 5px;
  z-index: 2;
}

.chat-info::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  right: 5%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.chat-info::before {
  content: " ";
  position: absolute;
  bottom: 100%;
  right: 4.3%;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent #d9d9d9 transparent;
}

.deliverd-info-box {
  width: 170px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deliverd-info-box2 {
  margin-top: 3px;
}

.deliverd-info-box-label {
  font-size: 10px;
  color: #475569;
}

.deliverd-info-box-value,
.read-info-box-value {
  display: flex;
  gap: 3px;
  align-items: center;
}

.deliverd-info-box-value-date,
.deliverd-info-box-value-time {
  font-size: 10px;
  color: #202224;
  font-family: "puvi-medium";
}

.deliverd-info-box-value-dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #202224;
}

.read-info-box-value-date,
.read-info-box-time {
  font-size: 10px;
  color: #3d65fa;
  font-family: "puvi-medium";
}

.read-info-box-value-dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #3d65fa;
}

.chat-content-body {
  width: 100%;
  padding-right: 4px;
}

.chat-content-body p {
  font-size: 14px;
}

.star-icon-body {
  width: 10px;
  height: 100%;
  padding: 6px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: absolute;
  top: 0px;
  right: 6px;
}

.star-icon-sub-body {
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat {
  max-width:70%;
  min-width: 160px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 4px;
  padding: 1px;
  margin: 10px;
  margin-left: 12px;
  height: auto;
  word-wrap: break-word;
}

.chat.incoming {
  flex-direction: row-reverse;
  align-self: flex-start;
}

.chat.outgoing {
  flex-direction: row;
  align-self: flex-end;
}

.messgae-read-img,
.messgae-delivered-img,
.messgae-sent-img,
.messgae-failed-img,
.messgae-pending-img {
  width: 12px;
  height: 12px;
}

.chat-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
}

.reaction-container-body {
  position: relative;
}

.reaction-box {
  width: 20px;
  height: 20px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.1s;
  cursor: pointer;
}

.reaction-emoij-box-right {
  width: 300px;
  height: 200px;
  position: absolute;
  top: 120%;
  right: -100px;
  z-index: 3;
  border-radius: 6px;
}

.reaction-emoij-box-left {
  width: 300px;
  height: 200px;
  position: absolute;
  top: 120%;
  left: -100px;
  z-index: 3;
  border-radius: 6px;
}

.reaction-emoij-box {
  width: 300px;
  height: 200px;
  position: absolute;
  top: 120%;
  right: 0;
  z-index: 3;
  border-radius: 6px;
}

.chat-wrapper:hover .reaction-box {
  opacity: 1;
}

.reaction-box img {
  width: 90%;
  height: 90%;
  opacity: 0.5;
  object-fit: fill;
}

.message {
  width: 100%;
  height: 100%;
  border-radius: 0px 0px 0px 8px;
}

.msg-contenet-and-footer-incomeing {
  padding: 16px;
  margin-top: 4px;
  border-radius: 6px 6px 6px 0px;
  background-color: #6383fc;
  position: relative;
}

.msg-contenet-and-footer-outgoing {
  padding: 16px;
  margin-top: 4px;
  border-radius: 6px 6px 0px 6px;
  background-color: #e6ebff;
  position: relative;
}

.parent-reaction-text {
  display: flex;
  position: relative;
}

.reaction-msg-incoming {
  width: auto;
  height: 20px;
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 0px 3px;
  cursor: pointer;
  border-radius: 30px;
  position: absolute;
  top: 8px;
  right: 1%;
  background-color: #e6ebff;
  border: 2px solid #6383fc;
}

.reaction-msg-outgoing {
  width: auto;
  height: 20px;
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 0px 3px;
  cursor: pointer;
  border-radius: 30px;
  position: absolute;
  top: 8px;
  left: 1%;
  background-color: #6383fc;
  border: 2px solid #e6ebff;
}

.reaction-text {
  width: 15px;
  height: 15px;
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: center;
}

.reaction-text-details-contet {
  width: 250px;
  height: auto;
  position: absolute;
  top: 120%;
  left: -400%;
  z-index: 1;
}

.reaction-text-details-content-incomeing {
  width: 200px;
  height: auto;
  position: absolute;
  top: 30px;
  right: -100px;
  z-index: 3;
  border-radius: 6px;
  color: #203758;
  background-color: white;
  border: 1px solid #e6ebff;
}

.reaction-text-details-content-outgoing {
  width: 200px;
  height: auto;
  position: absolute;
  top: 30px;
  left: -100px;
  z-index: 1;
  color: #3f4857;
  border-radius: 6px;
  background-color: white;
  border: 1px solid #e6ebff;
}

.reaction-text-details-content {
  width: 100%;
  height: 42px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
}

.reaction-text-details-name-box {
  width: 85%;
  height: 100%;
  text-indent: 5px;
}

.reaction-text-details-name {
  width: 100%;
  height: 50%;
  font-size: 11px;
  align-content: center;
  font-family: "puvi-medium";
}

.reaction-text-details-time {
  width: 100%;
  height: 50%;
  font-size: 10px;
  align-content: center;
}

.reaction-text-details-emoji {
  width: 15%;
  height: 100%;
  font-size: 14px;
  align-content: center;
  text-align: center;
}

.template-chat-style .chat-content-body {
  padding-right: 0px;
  /* padding-left: 10px; */
}

.chat-template-media-container {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
 
}

.template-cover-img{
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 10px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../src/app\ assets/media/blur.jpg");
  position: relative;
}

.template-info-icon{
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0px 8px;
}

.template-info-icon img{
  width: 18px;
  height: 18px;
}

.template-media-down-icon-box{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-template-preview-media-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.chat-template-preview-media-content img {
  width: 100%;
  border-radius: 10px;
  height: 100%;
  object-fit: fill;
}

.chat-template-preview-button-body {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 3px;
  border-bottom: 1px solid #ffffff;
}

.chat-template-preview-container {
  cursor: not-allowed;
  margin-top: 1px;
  border-top: 1px solid #ffffff;
}

.chat-template-preview-button {
  width: 100%;
  height: 30px;
  margin-top: 5px;
  padding: 5px;
  font-size: 11px;
  font-family: "puvi-medium";
  background-color: #e6ebff;
  color: #3d65fa;
  display: flex;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  cursor: not-allowed;
  pointer-events: none;
  border-radius: 8px 8px 0px 8px;
}

.chat-template-preview-button-img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.tooltip {
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #0086ff;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 11px;
  white-space: nowrap;
  opacity: 0;
  animation: fadeInOut 2s ease-in-out forwards;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* Chat compount Style End */

/* TextChat compount Style Start */
.msg-contenet-and-footer-incomeing .msg-chat-text-content {
  color: white;
}

.msg-contenet-and-footer-incomeing p {
  font-family: "puvi-medium" !important ;
}

.msg-contenet-and-footer-outgoing .msg-chat-text-content {
  color: #000000;
}

.msg-contenet-and-footer-incomeing .msg-chat-text-content a{
  color: white;
}


.msg-contenet-and-footer-outgoing .msg-chat-text-content a {
  color: #000000;
}


.msg-chat-text-content {
  font-size: 12px;
  line-height: 15px;
  font-family: "puvi-regular";
}

.msg-chat-text-content pre,
.msg-chat-text-content pre code {
  background-color: transparent;
}

.msg-chat-text-content blockquote {
  border-left: none;
}
/* TextChat compount Style End */

/* System Chat Style Start */
.systemchat-body {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.systemchat-sub-body {
  min-width: auto;
  max-width: 70%;
  padding: 7px 10px;
  height: auto;
  text-align: center;
  border-radius: 5px;
  margin-top: 5px;
  background-color: rgba(204, 195, 195, 0.212);
}

.systemchat-sub-body p {
  font-size: 11px;
  color: #0086ff;
  word-spacing: 1px;
  text-align: center;
}

.deleted-Chat-body {
  width: 100%;
  height: 100%;
  font-size: 11px;
  align-content: center;
}

.incoming .deleted-Chat-body {
  color: white;
}

.outgoing .deleted-Chat-body {
  color: black;
}

.unsupported-Chat-body {
  width: 100%;
  height: 100%;
  font-size: 14px;
  padding-top: 2px;
  color: #3f4857;
  align-content: center;
}

.incoming .unsupported-Chat-body {
  color: white;
}

.Selectionrequest-Chat-body {
  max-width: 245px;
  min-width: 200px;
  height: 100%;
  font-size: 11px;
  padding-top: 2px;
  margin-bottom: 4px;
  color: #3f4857;
  align-content: center;
  border-bottom: 1px solid #ffffff;
}

.Selectionrequest-Chat {
  padding: 8px 8px;
  padding-right: 15px;
}

.selection-chat-body {
  width: 100%;
  height: 100%;
  font-size: 14px;
  padding-top: 2px;
  color: #ffffff;
  align-content: center;
}

.selection-button-body {
  cursor: not-allowed;
}

.selection-button {
  width: 100%;
  height: 30px;
  margin-top: 5px;
  font-size: 11px;
  font-family: "puvi-medium";
  color: #3d65fa;
  display: flex;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  cursor: not-allowed;
  pointer-events: none;
  border-top: 1px solid #ffffff;
}

.contact-chat-body {
  display: flex;
  gap: 5px;
  flex-direction: column;
  margin-top: 8px;
  padding: 5px 5px;
  border-radius: 4px;
  color: #3f4857;
  font-size: 11px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contact-chat-row {
  display: flex;
}

.contact-chat-label {
  width: 90px;
  word-wrap: none;
  font-family: "puvi-semi-bold";
}

.contact-chat-more-button-body {
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 5px;
  margin-bottom: 3px;
  border-radius: 4px;
  justify-content: center;
  border: 1px solid #b6d5f0b6;
  cursor: pointer;
}

.contact-chat-more-button {
  padding: 4px 22px;
  align-content: center;
  border-radius: 4px;
}

.contact-chat-popup-body {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.contact-chat-popup-content-box {
  padding: 20px 15px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-content: center;
  background-color: white;
}

.contact-chat-popup-close {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact-chat-popup-close div {
  font-size: 18px;
  font-family: "puvi-bold";
}

.contact-chat-popup-close img {
  width: 20px;
  height: 20px;
  padding: 3px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #f1f2f6;
}

.contact-chat-popup-content {
  margin-top: 25px;
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.contact-chat-popup-row {
  display: flex;
}

.contact-chat-popup-label {
  width: 125px;
  font-size: 14px;
  align-content: top;
  font-family: "puvi-semi-bold";
}

.contact-chat-popup-value {
  font-size: 14px;
}

.contact-chat-popup-value ul {
  width: auto;
  padding: 0;
  list-style-type: none;
}

.contact-chat-popup-value ul li {
  margin-left: 0px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  text-wrap: nowrap;
  margin-bottom: 10px;
}

.contact-popup-email-label,
.contact-popup-phone-label {
  font-size: 14px;
  width: 49px;
}

.contact-popup-email-value,
.contact-popup-phone-value {
  margin-left: 2px;
}
/* System Chat Style End */

/* Preview compount Style Start */
.chat-preview-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: start;
}

.chat-preview-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 38px;
  font-weight: 600;
  cursor: pointer;
  color: white;
  padding-bottom: 5px;
  width: 2.3%;
  height: 6%;
  display: grid;
  place-items: center;
}

.chat-preview-show-div {
  width: 90%;
  height: 75vh;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0);
}

.chat-preview-img-content {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.chat-preview-download-div {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}

.chat-preview-download-btn {
  width: 24px;
  height: 24px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-preview-download-btn-img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.chat-preview-pdf-content {
  width: 90%;
  height: 100vh;
  margin-right: 5%;
  margin-left: 5%;
  border: none;
}

.chat-preview-text-content pre {
  width: 100%;
  height: 90vh;
  overflow-y: scroll;
  overflow-x: scroll;
}

.chat-preview-warning-text {
  color: white;
  display: grid;
  place-items: center;
  width: 100%;
  height: 90vh;
}
/* Preview compount Style End */

/* VideoPlayer compount Style Start */
.video-container {
  position: relative;
  width: 100%;
  height: 100%;
}

video {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
/* VideoPlayer compount Style End */

/* AudioChat compount Style Start */
.audio-chat-container {
  width: 100%;
  min-width: 190px;
  min-height: 50px;
  display: flex;
  background-color: #f0eefb;
  background-color: #b6d5f0b6;
  border-radius: 10px;
  overflow: hidden;
}

.audio-icon-box {
  width: 30%;
  min-height: 50px;
  display: grid;
  place-items: center;
  background-color: white;
}

.audio-icon {
  width: 20px;
  height: 20px;
}

.audio-content-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.audio-content-details {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
}

.audio-content-title {
  font-size: 12px;
  font-weight: 400;
}

.audio-content-size {
  font-size: 10px;
  font-weight: 400;
  color: "rgba(0,0,0,0.6)";
}

.audio-content-symbol {
  width: 25%;
  display: grid;
  place-items: center;
}

.audio-download-icon {
  width: 18px;
  height: 18px;
}

.audio-play-pause-icon {
  width: 18px;
  height: 18px;
}
/* AudioChat compount Style End */

/* FileChat compount Style Start */
.file-chat-container {
  width: 100%;
  min-width: 190px;
  height: 70%;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  margin-bottom: 5px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../src/app\ assets/media/blur.jpg");
}

.files-body .file-chat-container {
  min-width: 1px;
}

.pdf-thumbnail {
  width: 100%;
  height: 75px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 10px 10px 0px 0px;
}

.files-chat-container {
  display: flex;
  cursor: pointer;
}

.file-icon-box {
  width: 50px;
  height: 50px;
  display: grid;
  place-items: center;
  border-radius: 4px;
}

.file-icon {
  width: 23px;
  height: 23px;
}

.file-content-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 5px;
}

.file-content-details {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.file-content-title {
  font-size: 12px;
  font-weight: 400;
}

.file-content-size {
  font-size: 10px;
  padding: 2px 5px;
  font-weight: 400;
  color: #475569;
}

.incoming .file-content-title,
.incoming .file-content-size {
  /* color: white; */
  color: black;
}

.outgoing .file-content-title {
  color: black;
}

.file-content-symbol {
  display: grid;
  place-items: center;
}

.file-caption {
  font-size: 12px;
  font-weight: 400;
  padding: 5px;
  display: inline-block;
  width: 100%;
  word-wrap: break-word;
}
/* FileChat compount Style End */

.file-download-icon {
  width: 18px;
  height: 18px;
  margin-right: 3px;
}

.file-download-icon img {
  width: 100%;
  height: 100%;
}

.video-file-play-icon {
  width: 100%;
  height: 25px;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.video-file-play-icon img {
  width: 18px;
  height: 18px;
}

.video-file-download-icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
}

.video-file-download-icon img {
  width: 25px;
  height: 25px;
}

.image-file-download-icon {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-file-download-icon img {
  width: 25px;
  height: 25px;
}

/* ImageChat compount Style Start */
.chat-image-container {
  width: 100%;
  min-width: 210px;
  height: 160px;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 6px;
  border-radius: 10px;
}

.incoming .audio-content-title,
.incoming .audio-content-size {
  color: #ffffff;
}

.outgoing .audio-content-title,
.outgoing .audio-content-size {
  color: #000000;
}

.chat-image-cover-div {
  width: 100%;
  height: 100%;
  position: relative;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../src/app\ assets/media/blur.jpg");
}

.image-file-icon{
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: start;
  padding:0px 5px;
  /* padding-top: 4px; */
}

.image-file-icon img{
  width: 18px;
  height: 18px;
  opacity: 0.5;
}

.incoming .chat-image-cover-div {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../src/app\ assets/media/blur.jpg");
}

.outgoing .chat-image-cover-div {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../src/app\ assets/media/blur.jpg");
}

.chat-image-caption {
  width: 100%;
  padding: 5px;
  font-size: 12px;
  display: inline-block;
  word-wrap: break-word;
}
/* ImageChat compount Style End */

/* VideoChat compount Style Start */
.chat-video-play-button-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  padding: 10px;
  cursor: "pointer";
}

.chat-video-container {
  width: 100%;
  min-width: 210px;
  height: 128px;
  border-radius: 6px;
  cursor: pointer;
}

.chat-video-cover-div {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../src/app\ assets/media/blur.jpg");
}

.incoming .chat-video-cover-div {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../src/app\ assets/media/blur.jpg");
}

.outgoing .chat-video-cover-div {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../src/app\ assets/media/blur.jpg");
}

.chat-video-caption {
  width: 100%;
  padding: 5px;
  font-size: 12px;
  display: inline-block;
  word-wrap: break-word;
}
/* VideoChat compount Style End */

/* LocationChat compount Style Start */
.chat-location-container {
  min-width: 120px;
  height: 128px;
  border-radius: 10px;
  cursor: pointer;
}

.map-preview {
  width: 100%;
  height: 128px;
  border-radius: 6px;
}

.map-preview-img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  object-fit: cover;
}

.chat-location-caption {
  width: 100%;
  padding: 5px;
  word-wrap: break-word;

}

.chat-location-caption-address {
  font-size: 12px;
  color: #000;
  margin-bottom: 5px;
}

.chat-location-caption-sub-address {
  font-size: 10px;
  font-weight: 400;
  margin-bottom: 5px;
  color: rgba(0, 0, 0, 0.6);
}

.incoming .chat-location-caption-address,
.incoming .chat-location-caption-sub-address,
.incoming .chat-location-caption-url {
  color: white;
}

.chat-location-caption-url{
  text-decoration: none;
  color: #000000;
}

.text-al{
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}
/* LocationChat compount Style End */

/* LocationRequestChat compount Style Start */
.location-request-container {
  width: 190px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
}

.request-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: not-allowed;
  margin-bottom: 8px;
  cursor: default;
}

.request-button:disabled {
  background-color: #0056b3;
}

.request-caption {
  font-size: 12px;
  font-weight: 400;
  padding: 5px;
  display: inline-block;
  width: 100%;
  word-wrap: break-word;
  text-align: center;
  color: #000000;
}

.incoming .request-caption {
  color: white;
}
/* LocationRequestChat compount Style End */

/* StickerChat compount Style Start */
.sticker-container {
  width: 100px;
  height: 100px;
}
/* StickerChat compount Style End */

/* TemplateChat compount Style start */
.template-chat-style {
  padding: 0px;
  padding-bottom: 15px;
}

.chat-template-container {
  width: 100%;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.chat-template-header-text {
  font-size: 11px;
  font-family: "puvi-bold";
  padding: 8px;
  padding-bottom: 0px;
}

.chat-template-body-text {
  font-size: 11px;
  color: #3f4857;
  padding: 8px;
  padding-bottom: 0px;
}

.chat-template-footer-text {
  font-size: 11px;
  color: #677fab;
  padding: 8px;
  padding-bottom: 0px;
}

.chat-template-file-info-container {
  width: 98%;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 5px;
  border-radius: 0px 0px 6px 6px;
  background-color: #b6d5f0b6;
}

.chat-template-non-text-icon {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 4px;
  margin: 0;
}

.chat-template-non-text-icon img {
  width: 100%;
  height: 100%;
}

.chat-template-content-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}
/* TemplateChat compount Style End */

/* ChatDetails compount Style Start */
.chat-details {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.details-header {
  width: 87%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.details-header-head {
  display: flex;
  justify-content: end;
  margin-top: 17px;
}

.details-header-head p {
  font-size: 14px;
  line-height: 16.06px;
  font-family: "puvi-semi-bold";
}

.details-header-head img {
  object-fit: contain;
  cursor: pointer;
}

.header-details {
  width: 100%;
  min-height: 83px;
  margin-top: 16px;
  border-radius: 10px;
  padding: 20px 10px;
  padding-top: 0px;
  padding-right: 5px;
  border: 1px solid #e9e9e9;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 3px 5px;
}

.details-open-tap-icon-box {
  width: 100%;
  height: 15px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.details-open-tap-icon-box img {
  width: 13px;
  height: 13px;
}

.header-details-sub-box {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: start;
}

.header-details-profile {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.header-details-info-body {
  max-width: 68%;
}

.header-details-info-name {
  font-size: 16px;
  color: #1e293b;
  font-family: "puvi-semi-bold";
}

.header-details-info-number {
  font-size: 14px;
  color: #475569;
  height: 20px;
  margin-top: 4px;
}

.details-body {
  width: 87%;
  height: calc(100% - 139px);
}

.details-drop-down {
  width: 100%;
  height: 35px;
  padding-left: 15px;
  padding-right: 2px;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e9e9e9;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 3px 5px;
}

.details-drop-down p {
  font-size: 12px;
  color: #475569;
  line-height: 20px;
  font-family: "puvi-medium";
}

.right-arrow {
  color: #9d9d9d;
  width: 19px;
  height: 19px;
  background-color: white;
}
/* ChatDetails compount Style End */

.close-trigger-form-popup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
}

/* StarredMessagePage compount Style Start */
.starred-messages-page {
  width: 100%;
  height: 100%;
  position: relative;
}

.starred-header {
  width: 100%;
  height: 72px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
}

.back {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.starred-header-head {
  margin-left: 10px;
}

.starred-header-head p {
  font-size: 14px;
  color: #20201f;
  font-family: "puvi-medium";
}

.starred-messages-container {
  width: 100%;
  height: calc(100% - 72px);
  overflow-y: auto;
  background-color: white;
  padding: 0px;
}

.starred-messages-container::-webkit-scrollbar {
  display: block;
  scrollbar-width: 3px;
  width: 4px;
}

.starred-messages-container::-webkit-scrollbar-track {
  background-color: white;
}

.starred-messages-container::-webkit-scrollbar-thumb {
  background-color: #d8d2c2;
  border-radius: 2px;
}

.no-message {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.75);
}

.chat-last-scroll-control-body {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 12%;
  right: 3%;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  background-color: rgba(204, 195, 195, 0.315);
}

.chat-last-scroll-control-body img {
  width: 40%;
  height: 40%;
  opacity: 0.8;
  cursor: pointer;
}

.starred-date {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.starred-date-span {
  width: 68px;
  display: grid;
  place-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 9px;
  color: #727489;
  font-family: "puvi-regular";
}

.started-msg-outbox {
  position: relative;
  cursor: pointer;
  border-radius: 6px;
  margin-bottom: 10px;
  width: 100%;
}

.started-msg-outbox .chat-location-container {
  min-width: 10px;
}

.started-msg-outbox .chat-image-container {
  min-width: 100%;
}

.started-msg-outbox .file-chat-container {
  min-width: 1px;
  height: 70%;
}

.started-msg-outbox .pdf-thumbnail {
  height: 90px;
}

.started-msg-outbox .chat {
  max-width: 100%;
  width: 100%;
}

.started-msg-outbox .outgoing {
  width: 100%;
}

.started-msg-outbox .chat-wrapper {
  justify-content: center !important;
}

.started-msg-outbox .chat-template-container {
  min-width: 0px;
}

.started-msg-outbox .chat-video-container {
  min-width: 1px;
}

.started-msg-outbox .reaction-container-body {
  display: none;
}

.started-msg-inbox {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: none;
}

.started-msg-scroll-loading {
  padding: 0px 0px;
}
/* StarredMessagePage compount Style End */

/* FilePage compount Style Start */
.files-page {
  width: 100%;
  height: 100%;
  position: relative;
}

.files-header {
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  border-bottom: 1px solid #e9e9e9;
}

.files-back-arrow {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.files-header-head {
  margin-left: 10px;
}

.files-header-head p {
  font-size: 14px;
  color: #20201f;
  font-family: "puvi-medium";
}

.files-body {
  width: 100%;
  height: calc(100% - 72px);
  overflow-y: scroll;
}

.files-no-message {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.75);
}

.file-all-msg-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-date {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-date-span {
  width: 68px;
  display: grid;
  place-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 9px;
  color: #727489;
  font-family: "puvi-regular";
}

.file-page-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.files-body::-webkit-scrollbar {
  display: block;
  scrollbar-width: 30px;
  width: 4px;
}

.files-body::-webkit-scrollbar-track {
  background-color: white;
}

.files-body::-webkit-scrollbar-thumb {
  background-color: #d8d2c2;
  border-radius: 2px;
}

.files-body-scroll-loading {
  padding: 10px 0px;
}
/* FilePage compount Style End */

/* FilePagechat compount Style Start */
.file-chat-message {
  width: 90%;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: #e6ebff;
}

.file-chat-message .chat-image-container {
  min-width: 10px;
}

.file-chat-message .chat-video-container {
  min-width: 10px;
}
/* FilePagechat compount Style End */

/* ChatFooter compount Style Start */
.chatting-bar {
  width: 100%;
  height: 49px;
  position: relative;
  border-top: 1px solid #e9e9e9;
}

.chat-media-prev-box {
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 100%;
}

.chatting-field-box {
  width: 100%;
  height: 100%;
  padding-right: 17px;
  display: flex;
  align-items: end;
  background-color: #fff;
}

.chatting-field-box2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #a0a0a0;
  background-color: #e9e9e92b;
}

.chatting-field-box2 span {
  color: #0086ff;
  cursor: pointer;
  margin-left: 10px;
  text-decoration: underline;
  font-family: "puvi-semi-bold";
}

.chatting-field-box3 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  font-size: 12px;
  color: #a0a0a0;
  background-color: #e9e9e92b;
}

.chatting-field-box3 span {
  cursor: pointer;
  color: #0086ff;
  text-decoration: underline;
  font-family: "puvi-semi-bold";
}

.chat-fields {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: end;
  border-radius: 6px;
}

.input-container {
  width: 90%;
  height: 100%;
  padding-right: 10px;
}

#message-input {
  border: none;
  width: 100%;
  height: 100%;
  font-size: 12px;
  padding: 10px 5px;
  resize: none;
  display: flex;
  align-items: center;
  outline: none;
}

#message-input::placeholder {
  color: #afafaf;
  font-size: 12px;
  font-weight: 500;
}

#message-input::-webkit-scrollbar {
  display: none;
}

.chat-kb-options-box {
  display: flex;
  align-items: center;
  gap: 13px;
  height: 100%;
}

.chat-emoji-icon {
  width: 19px;
  height: 19px;
  cursor: pointer;
}

.chat-kb-attachment-box {
  width: 19px;
  height: 19px;
}

.chat-attachments-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.chat-more-icon {
  width: 19px;
  height: 22px;
  cursor: pointer;
  transform: rotate(90deg);
}

.chat-kb-emoji-box {
  width: 300px;
  height: 300px;
  position: absolute;
  right: 10px;
  bottom: 50px;
  z-index: 1;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.template-drop-down-box {
  width: 15%;
  max-width: 115px;
  height: 30px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 10px;
  z-index: 1;
  bottom: 90%;
  border: 1px solid #edf1f3;
  background-color: white;
}

.template-drop-down-box::after {
  content: " ";
  position: absolute;
  top: 100%;
  right: 39%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.template-drop-down-box::before {
  content: " ";
  position: absolute;
  top: 100%;
  right: 38%;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: #edf1f3 transparent transparent transparent;
}

.template-drop-down-box div {
  width: 100%;
  height: 30px;
  padding-left: 4px;
  align-content: center;

}

.template-drop-down-box div label {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 14px;
  font-family: "puvi-medium";
}

.template-drop-down-box div:hover {
  background-color: #e5e4e7;
  cursor: pointer;
}

.more-menu-drop-down-box {
  width: 19%;
  max-width: 133px;
  height: 32px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 10px;
  z-index: 1;
  bottom: 90%;
  border: 1px solid #edf1f3;
  background-color: white;
}

.more-menu-drop-down-box::after {
  content: " ";
  position: absolute;
  top: 100%;
  right: 8%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.more-menu-drop-down-box::before {
  content: " ";
  position: absolute;
  top: 100%;
  right: 7%;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: #edf1f3 transparent transparent transparent;
}

.more-menu-drop-down-box div {
  width: 100%;
  height: 30px;
  padding: 0px 4px;
}

.more-menu-drop-down-box-item {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 14px;
  font-family: "puvi-medium";
}

.more-menu-drop-down-box div:hover {
  background-color: #e5e4e7;
  cursor: pointer;
}

.kb-drop-down-box {
  width: 15%;
  max-width: 115px;
  height: 90px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 50px;
  z-index: 1;
  bottom: 90%;
  border: 1px solid #edf1f3;
  background-color: white;
}

.kb-drop-down-box::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 67%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.kb-drop-down-box::before {
  content: " ";
  position: absolute;
  top: 100%;
  left: 67%;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: #edf1f3 transparent transparent transparent;
}

.kb-drop-down-box div {
  width: 100%;
  height: 30px;
  padding-left: 4px;
}

.kb-drop-down-box div label {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 14px;
  font-family: "puvi-medium";
}

.kb-drop-down-box div:hover {
  background-color: #e5e4e7;
  cursor: pointer;
}
/* ChatFooter compount Style End */

/* Attachments compount Style Start  */
.attachments-container {
  display: flex;
  justify-content: start;
  align-items: center;
}

.attachment {
  min-width: 70px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  background-color: #f0eefb;
  justify-content: center;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
}

.attachment p {
  font-size: 12px;
  padding-top: 5px;
  font-weight: 400;
}

.notification {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 1000;
}

.notification-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  max-width: 300px;
}

.close-btn {
  background-color: #652dc1;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}
/* Attachments compount Style End  */

/* Media compount Style Start  */
.media-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  padding-left: 10px;
  align-items: center;
  background-color: #e5f1ff;
  position: relative;
  z-index: 1;
}

.uploaded-media {
  width: 11%;
  height: 90%;
  border: 1px solid #f0eefb;
  border-radius: 4px;
}

.remove-button {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 18px;
  height: 18px;
  padding: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f0eefb;
  cursor: pointer;
  z-index: 10;
}

.remove-button img {
  width: 18px;
  height: 18px;
}

.preview-container {
  width: 100%;
  height: 100%;
}
/* Media compount Style End  */

/* EmojiContainer compount Style Start */
.emoji-container {
  width: 99%;
  height: 99%;
  border-radius: 6px;
}

.emoji-container div {
  height: 100%;
}

em-emoji-picker {
  --background-rgb: 85, 170, 255;
  --category-icon-size: 24px;
  --color-border-over: rgba(0, 0, 0, 0.1);
  --color-border: none;
  --font-size: 14px;
  --rgb-accent: 33, 74, 154;
  --rgb-background: 240, 247, 255;
  --rgb-color: 97, 124, 180;
  --rgb-input: 33, 74, 154, 0.1;
  height: 41vh;
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  background-color: #f0f7ff;
  border-radius: 6px;
}
/* EmojiContainer compount Style End */

/* AudioRecorder compount Style start */
.audio_container {
  width: 60%;
  height: 6vh;
}

.delete-icon {
  width: 22px;
  height: 22px;
}

.delete-icon img {
  width: 22px;
  height: 22px;
}

.reactmic {
  width: 100%;
  height: 6vh;
}
/* AudioRecorder compount Style End */

/* UploadPreview compount Style Start */
.preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0eefb;
}

.preview p {
  font-size: 6px;
  padding-top: 5px;
}
/* UploadPreview compount Style End */

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

#nprogress .bar {
  background: blue !important;
  height: 2.2px !important;
  box-shadow: 0 0 2px blue, 0 0 2px blue !important;
}

/* page loader css */
.progress {
  position: absolute;
  height: 3px;
  display: block;
  z-index: 9999;
  width: 100%;
  background-color: white;
  border-radius: 2px;
  background-clip: padding-box;
  overflow: hidden;
}

.progress .indeterminate {
  background-color: black;
}

.progress .indeterminate:before,
.progress .indeterminate:after {
  content: "";
  position: absolute;
  background-color: #3d65fa;
  top: 0;
  bottom: 0;
  will-change: left, right;
  animation: indeterminate 2s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.progress .indeterminate:after {
  animation: indeterminate-short 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation-delay: 1s;
}

/* General Popup Styles */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Dark background */
  z-index: 9; /* Ensure it's behind the popup */
}

.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  z-index: 10;
}

/* Media Popup Specific Styles */
.media-popup .popup-header {
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
}

.back-button,
.download-button {
  background-color: #652dc1;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.popup-content {
  max-width: 90%;
  max-height: 90%;
}

/* Document Popup Specific Styles */
.document-popup {
  display: flex;
  justify-content: center;
  align-items: center;
}

.document-popup .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  padding: 10px;
  cursor: pointer;
}

.link-preview {
  display: flex;
  align-items: center;
  justify-content: start;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 3px;
  max-height: 70%;
  width: 90%;
  height: 17.5vh;
}

.link-preview .img {
  width: 30%;
  height: 90%;
  margin-right: 10px;
  border-radius: 4px;
  background-color: white;
  padding: 1px;
}

.link-preview .img {
  width: 85px;
  height: 85px;
  margin-right: 10px;
  border-radius: 4px;
  overflow: hidden;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  background-color: white;
}

.link-preview-info h4 {
  font-size: 16px;
  margin-bottom: 4px;
}

.link-preview-info p {
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
}

/* Link preview unknow code start */
.link-preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  color: white;
  max-height: 10vh;
  background-color: #652dc1;
  border-bottom: 1px solid #ddd;
}

.link-preview .img {
  flex: 1;
  max-width: 80px;
  max-height: 60px;
  margin-left: 0;
  margin-right: 10px;
}

.img img {
  object-fit: cover;
}

.link-preview-info {
  flex: 3;
  display: flex;
  flex-direction: column;
}

.link-preview-info h4 {
  font-size: 12px;
}

.link-preview-info p {
  font-size: 10px;
  color: white;
}
/* Link preview unknow code End */

/*unknow code Start  */
.file-content {
  width: 100%;
  display: flex;
  align-items: center;
}

.viewer-container {
  width: 90%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #333;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  z-index: 10;
  color: #fff;
}

.popup {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
}

.document-viewer {
  flex-grow: 1;
  border: none;
  width: 100%;
  height: 100%;
}

.pdf-header {
  height: 5vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* MArkdowns */
i {
  font-style: italic;
}

b {
  font-weight: bold;
}

s {
  text-decoration: line-through;
}

pre {
  font-family: monospace;
  border-radius: 5px;
  white-space: pre-wrap;
}

.incoming pre code {
  color: white;
}

.outgoing pre code {
  color: #000000;
}

code {
  font-family: monospace;
  background-color: #f4f4f4;
  padding: 2px 5px;
  border-radius: 3px;
  color: black;
}

li {
  margin-left: 20px;
  padding: 0px;
}

ol {
  margin: 0;
}

blockquote {
  border-left: 2px solid #ddd;
  padding-left: 10px;
  margin-left: 20px;
  margin-left: 0;
}

.controls-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
  box-sizing: border-box;
}

.video-progress-bar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;
}

.progress-bar {
  width: 80%;
  cursor: pointer;
  transition: all 0.1s ease;
}

.time-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 20%;
}

.controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.left-controls,
.right-controls {
  display: flex;
  align-items: center;
}

.control-button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 5px;
}

.volume-control {
  position: relative;
}

.volume-slider {
  position: absolute;
  top: -40px;
  width: 100px;
  cursor: pointer;
  display: none;
}

.volume-control:hover .volume-slider {
  display: block;
}

.highlight {
  animation: highlight 1s ease-in-out;
}
/*unknow code End  */

/*DropDown unknow code Start  */
.status-container,
.assignee-container {
  position: relative;
}

#drop {
  position: relative;
}

.dropdown-menu {
  width: 120px;
  position: absolute;
  top: 100%;
  left: 22%;
  max-height: 180px;
  overflow-y: scroll;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1;
}

.dropdown-menu::-webkit-scrollbar,
.assignee-dropdown-menu::-webkit-scrollbar {
  display: none;
}

.status-dropdown-item {
  padding: 5px;
  width: 100%;
  cursor: pointer;
}

.assignee-dropdown-item {
  padding: 5px;
  width: 100%;
  cursor: pointer;
}

.selected-drop-down {
  background-color: #f0f0f0;
  width: 100%;
}
/*DropDown unknow code End  */

/*Chat Template details start*/
.template-details-container {
  width: 70%;
  max-width: 956px;
  height: 90vh;
  padding: 20px;
  border-radius: 6px;
  background-color: #fff;
}

.template-details-buttons {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
}

.template-details-buttons-title {
  font-size: 16px;
  color: #000000;
  font-family: "puvi-medium";
}

.template-details-buttons-box {
  display: flex;
  gap: 9px;
  align-items: center;
  justify-content: space-between;
}

.template-details-buttons button {
  width: 97px;
  height: 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.template-details-send-btn {
  color: white;
  background-color: #3d65fa;
  cursor: pointer;
}

.template-details-main {
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  padding-top: 25px;
  justify-content: space-between;
}

.template-details-close-btn {
  color: #475569;
  background-color: #e9e9e9;
  cursor: pointer;
}

.template-chat-preview {
  width: 345px;
  overflow-y: scroll;
  border-radius: 8px 8px 0px 8px;
}

.template-chat-preview .template-preview-content {
  margin-top: 0px;
}

.template-details-params {
  width: calc(100% - 345px);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.template-details-header-params-div,
.template-details-body-params-div {
  width: 100%;
  height: auto;
  padding: 10px 0px;
}

.template-details-button-params-div {
  width: 100%;
  height: auto;
  padding: 10px 0px;
}

.template-details-header-params-div .file-upload-container {
  margin-left: 20px;
}

.template-details-header-params-div .file-container {
  width: auto;
}

.template-details-label {
  width: 65px;
  font-size: 14px;
  color: #000000;
  font-family: "puvi-medium";
  text-transform: uppercase;
}

.template-params-input-div {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.params-div {
  width: 100%;
  margin-top: 20px;
  padding-left: 35px;
  display: flex;
  flex-direction: column;
}

.url-param-input,
.params-div label {
  font-size: 16px;
  color: #475569;
  margin-bottom: 14px;
  font-family: "puvi-medium";
}

.params-div input {
  height: 44px;
  width: 200px;
  border-radius: 10px;
  outline: none;
  padding: 6px;
  border: 1px solid #e9e9e9;
}

/*Chat Template details End*/

@keyframes highlight {
  0% {
    background-color: #d1c9ff;
  }
  100% {
    background-color: transparent;
  }
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
