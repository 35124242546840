.dynamic-page-container {
  width: 100%;
  height: calc(100vh - 49px);
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* List Page Start */

.dynamic-list-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.dynamic-list-header {
  width: 100%;
  height: 75px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}

.dynamic-list-header-left-content {
  width: auto;
  height: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
}

.drop-down-re-load {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  cursor: pointer;
}

.drop-down-re-load img {
  width: 17px;
  height: 17px;
  object-fit: contain;
}

.search-details-close-body {
  width: auto;
  height: 44px;
  display: flex;
  gap: 5px;
  align-items: center;
  white-space: nowrap;
  padding: 0px 15px;
  border-radius: 4px;
  background-color: white;
  background-color: #e6ebff;
  z-index: 1;
}

.search-details-text {
  font-size: 12px;
  color: #000000;
}

.search-details-close {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
}

.search-details-close img {
  width: 70%;
  height: 70%;
}

.dynamic-list-header-right-content {
  width: auto;
  height: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0px;
  z-index: 2;
}

.Search-box-body {
  width: 323px;
  height: 44px;

  position: relative;
}

.Search-box {
  width: 100%;
  height: 100%;
  position: relative;
}

.Search-box-form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-form-submit-input {
  display: none;
}

.Search-box input {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  font-size: 14px;
  text-indent: 47px;
  border: none;
  outline: none;
  font-family: "puvi-Regular";
  z-index: 2;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
}

.Search-box input::placeholder {
  color: #475569;
  text-indent: 47px;
  font-size: 14px;
}

.input-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 10px;
  left: 16px;
  z-index: 2;
}

.input-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dynamic-search-drop-down-body {
  width: 100%;
  height: auto;
  padding-top: 13px;
  z-index: 1;
  max-height: 170px;
  overflow-y: auto;
  background-color: #fff;
  position: absolute;
  top: 30px;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  background-color: white;
}

.dynamic-search-drop-down-body::-webkit-scrollbar {
  display: none;
}

.dynamic-search-drop-down-content {
  width: 100%;
  height: 39px;
  font-size: 14px;
  color: #252525;
  padding: 0px 20px;
  align-content: center;
}

.dynamic-search-drop-down-content:hover {
  cursor: pointer;
  background-color: #e6ebff;
}

.dynamic-search-no-item-box {
  width: 100%;
  height: 150px;
  position: absolute;
  top: 30px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-size: 14px;
  font-family: "puvi-Regular";
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  background-color: white;
}

.dynamic-add-Btn {
  height: 44px;
  display: inline-flex;
  align-items: center;
  padding: 0px 25px;
  background-color: #3d65fa;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.dynamic-add-Btn div {
  display: flex;
  align-items: center;
  margin-right: 3px;
}

.dynamic-add-Btn-plus {
  font-family: "puvi-medium";
  font-size: 20px;
}

.dynamic-add-text {
  font-family: "puvi-medium";
  white-space: nowrap;
  font-size: 14px;
}

.dynamic-list-header-actions {
  width: 100%;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.dynamic-action-button-container .push-into-integration-button {
  border: 1px solid #652dc1;
  background-color: white;
  font-size: 14px;
  padding: 10px 22px;
  height: 60%;
  color: #652dc1;
  border-radius: 6px;
  margin-left: 20px;
  cursor: pointer;
}

.list-page-content-container {
  width: 97.5%;
  height: calc(100% - 80px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

/* Table start*/

.dynamic-table-sub-container {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow-y: scroll;
  border: 1px solid #e9e9e9;
  background-color: white;
}

.dynamic-table-sub-container::-webkit-scrollbar {
  display: none;
}

.dynamic-table-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  border-radius: 10px;
  background-color: white;
  position: relative;
}

.pagination-main-body {
  width: 100%;
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.dynamic-table {
  width: 100%;
  overflow: hidden;
  border-collapse: collapse;
}

.dynamic-table-thead-body {
  width: auto;
}

.dynamic-table thead th {
  height: 56px;
  max-height: 56px;
  text-align: start;
  font-size: 14px;
  font-family: "puvi-semi-bold";
  white-space: nowrap;
  padding-left: 10px;
  background-color: #e6ebff;
}

.dynamic-table th:first-child {
  padding-left: 15px;
}

.dynamic-table tbody tr td {
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 20px;
}

.dynamic-table tbody tr {
  width: 100%;
  font-size: 14px;
  height: 60px;
  font-family: "puvi-Regular";
  transition: 0.3s;
}

.dynamic-table-row {
  cursor: pointer;
  font-size: 14px;
  color: #475569;
  position: relative;
  background-color: white;
  border-bottom: 1px solid #e9e9e9;
}

.dynamic-table-row-blue {
  position: relative;
  cursor: pointer;
  background-color: #e6ebff;
  border-top: 1px solid white;
}

.table-check-box-td {
  padding-left: 15px !important;
}

.dynamic-table th:nth-child(2) {
  padding-left: 0px;
}

.dynamic-table tr td:nth-child(2) {
  font-family: "puvi-medium";
  padding-left: 0px;
}

.edit-del-box {
  width: 80px;
  height: 90%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 3px;
  right: 0px;
  transition: 0.3s;
  transform: translateX(84px);
  background-color: white;
  cursor: default;
}

.edit-del-box.blue {
  background-color: #e6ebff;
}

.dynamic-edit-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.dynamic-delete-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.dynamic-edit-icon img,
.dynamic-delete-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dynamic-table tr:hover .edit-del-box {
  transform: translate(0px);
}

.sorting-body {
  width: 24px;
  height: 24px;
  position: relative;
}

.asc_dis_order_icon {
  width: 100%;
  height: 100%;
  transition: 0.3;
  opacity: 0;
  cursor: pointer;
}

.asc_dis_order_icon img {
  z-index: 0;
  width: 100%;
  height: 100%;
}

.sorted img {
  z-index: 0;
  width: 100%;
  height: 100%;
}

.sorted {
  width: 100%;
  height: 100%;
  opacity: 1;
}

.th-sort-container:hover .asc_dis_order_icon {
  opacity: 1;
}

.sorting-list-box {
  position: absolute;
  top: 20px;
  left: 0;
  width: 129px;
  height: 78px;
  z-index: 1;
  overflow: hidden;
  border-radius: 10px;
  background-color: white;
  border: 1px solid #e9e9e9;
}

.sorting-list-box-field {
  width: 100%;
  padding-left: 16px;
  height: 39px;
  font-size: 14px;
  color: #475569;
  font-weight: 100;
  display: flex;
  align-items: center;
  justify-content: start;
  font-family: "puvi-regular";
  background-color: white;
}

.sorting-list-box-field:hover {
  cursor: pointer;
  background-color: #e6ebff;
}

.th-sort-container {
  width: 120px;
  position: relative;
  display: flex;
  gap: 2px;
  align-items: center;
}

.listcontentpage-loading-div {
  width: 100%;
  height: calc(100% - 57px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.listcontentpage-empty-msg-div {
  width: 100%;
  height: calc(100% - 57px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  background-color: white;
}

.local-load-listpage-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dynamic-table input[type="checkbox"] {
  width: 14px;
  height: 14px;
  appearance: none;
  cursor: pointer;
  border: 1px solid #475569;
}

.dynamic-table input[type="checkbox"]:checked {
  background-image: url("../../assets//dynamic\ page\ assets/checkbox-tick.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  border-radius: 2px;
  border-color: #434ee1;
}
/* Table end */

.form-input-div {
  position: relative;
  width: 330px;
  box-sizing: border-box;
  margin-bottom: 22px;
}

.form-text-div {
  position: relative;
  width: 330px;
  height: 131px;
  display: flex;
  flex-direction: column;
}

.form-text-div label {
  font-size: 16px;
  color: #475569;
  font-family: "puvi-Medium";
}

.form-text-div textarea {
  width: 100%;
  height: 100%;
  outline: none;
  margin-top: 10px;
  font-size: 14px;
  border-radius: 5px;
  padding: 16px;
  font-family: "puvi-Regular";
  border: 1px solid #e9e9e9;
  background-color: white;
  resize: none;
}

.form-input-div.social {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.social-input-label-body {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.social .error {
  width: 100%;
  position: absolute;
  top: 100%;
  right: 0;
}

.social-input-label-sub-body {
  width: 330px;
  height: 44px;
  display: flex;
  gap: 3px;
  padding: 0px 13px;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
}

.social-input-label {
  color: #969292;
  font-size: 12px;
  align-content: center;
}

.social .social-input-label-body div input {
  width: 100%;
  height: 100%;
  margin-top: 0px;
  border-radius: 0px;
  border: none;
}

.social .social-input-label-body div input::placeholder {
  font-size: 12px;
  color: #969292;
}

.form-input-div label {
  font-size: 16px;
  margin-bottom: 14px;
  color: #475569;
  font-family: "puvi-Medium";
}

.form-input-div input {
  width: 100%;
  height: 44px;
  outline: none;
  margin-top: 14px;
  font-size: 14px;
  text-indent: 8px;
  border-radius: 10px;
  font-family: "puvi-Regular";
  border: 1px solid #e9e9e9;
  background-color: #ffffff;
}

.form-warring-text {
  margin-top: 2px;
  margin-left: 2px;
  font-size: 12px;
  color: #ff6357;
}

.dob-input-div input,
.time-input-div input {
  text-indent: 3px;
}

.dynamic-dropdown {
  width: 100%;
  height: 44px;
  border-radius: 10px;
  padding: 0px 16px;
  padding-right: 5px;
  margin-top: 14px;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  border: 1px solid #e9e9e9;
  background-color: #ffffff;
  cursor: pointer;
}

.selected-content {
  width: 90%;
  overflow-x: auto;
  white-space: nowrap;
}

.dynamic-dropdown-content-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 18px;
  cursor: pointer;
}

.dynamic-dropdown-name {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 14px;
  font-family: "puvi-Regular";
  line-height: normal;
  overflow: hidden;
}

.dynamic-dropdown-dd {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dynamic-dropdown-dd img {
  width: 24px;
  height: 24px;
}

.date-time-picker {
  position: absolute;
  top: 100px;
  right: 110%;
  width: 100%;
  cursor: pointer;
  visibility: hidden;
}

.dynamic-dropdown-content {
  width: 100%;
  min-height: 40px;
  max-height: 160px;
  overflow-y: auto;
  position: absolute;
  top: 70px;
  z-index: 1;
  padding-top: 6px;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #e9e9e9;
  background-color: white;
}

.dynamic-dropdown-content::-webkit-scrollbar {
  display: none;
}

.dynamic-dropdown-name-box {
  width: 100%;
  height: 44px;
  color: #475569;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.dynamic-dropdown-name-box label {
  height: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.dynamic-dropdown-name-box label input {
  cursor: pointer;
}

.dynamic-dropdown-name-box:hover {
  background-color: #e6ebff;
  cursor: pointer;
}

.dynamic-dropdown-name-sub-box > div:nth-child(1) {
  color: #475569;
  font-family: "puvi-medium";
  cursor: pointer;
}

.dynamic-dropdown-name-sub-box > div:nth-child(2) {
  margin-top: 3px;
  cursor: pointer;
}

.dynamic-dropdown-name {
  position: relative;
  font-size: 14px;
  font-family: "puvi-Regular";
}

.dropdown-arrow {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-arrow img {
  width: 22px;
  height: 22px;
}

.phone-field-container .country-code-box {
  width: auto;
  height: 44px;
  padding-right: 5px;
  padding-left: 13px;
  border-radius: 10px 0px 0px 10px;
  background-color: #e6ebff;
  z-index: 2;
}

.phone-no-code-dropdown {
  position: absolute;
  width: 100%;
  top: 35px;
  padding-top: 10px;
  overflow-y: scroll;
  background-color: #fff;
  border: 1px solid #eaeaec;
  border-radius: 4px;
  z-index: 1;
  border-radius: 0px 0px 10px 10px;
}

.phone-no-code-dropdown::-webkit-scrollbar {
  display: none;
}

.country-code-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: "puvi-Regular";
  cursor: pointer;
  color: #475569;
}

.lookup-field-container {
  width: 100%;
  border-radius: 5px;
  height: 39px;
  margin-top: 6px;
  font-size: 14px;
  padding: 0px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fafcff;
  border: 1px solid #b0daff;
}

.lookup-field-icon-box {
  width: auto;
  height: 100%;
  display: flex;
  gap: 3px;
  align-items: center;
}

.lookup-field-icon {
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.lookup-field-icon img {
  width: 60%;
  height: 60%;
}

.lookup-field-cancel-icon img {
  width: 70%;
  height: 70%;
}

.popuplist-background-div {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
}

.tablecontent-box {
  width: 80%;
  height: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f7fb;
}

.popuplist-cancel-div {
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 10px;
  margin-top: 10px;
}

.popuplist-cancel-img-div {
  width: 25px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 60%;
  transition: 0.2s;
}

.popuplist-cancel-img-div:hover {
  background-color: #ddeafc;
}

.popuplist-cancel-img-div img {
  width: 80%;
  height: 80%;
  object-fit: contain;
}

.tablecontent {
  width: 100%;
  height: 90%;
  display: flex;
  align-items: start;
  justify-content: center;
  overflow-y: auto;
  background-color: #f5f7fb;
}

/* User Component Start */
.user-field-container {
  width: 100%;
  height: 44px;
  font-size: 14px;
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.user-field-box {
  width: 100%;
  height: 100%;
  padding: 0px 16px;
  padding-right: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  background-color: #ffffff;
}

.user-dropdown {
  position: absolute;
  width: 100%;
  top: 35px;
  left: 0px;
  max-height: 120px;
  padding-top: 8px;
  overflow-y: scroll;
  background-color: #fff;
  border: 1px solid #eaeaec;
  border-radius: 4px;
  z-index: 1;
  margin-bottom: 10px;
}

.user-dropdown::-webkit-scrollbar {
  display: none;
}

.user-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 39px;
  font-size: 14px;
}

.user-container:hover {
  cursor: pointer;
  background-color: #e6ebff;
}

.user-sub-container {
  width: 100%;
  padding: 0px 10px;
}

.user-sub-container-name {
  font-size: 14px;
  color: #475569;
  font-family: "puvi-medium";
}

.user-sub-container-mail {
  font-size: 12px;
  margin-top: 2px;
  color: #00000059;
}
/* User Component End */

/* Form page css end */

/*  Confirmation popup css start */
.dynamic-record-form-alertbody {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 4;
}

.dynamic-record-alert-content {
  border-radius: 10px;
  width: 398px;
  padding: 20px;
  padding-left: 37px;
  padding-bottom: 30px;
  text-align: center;
  position: relative;
  background-color: #ffffff;
}

.dynamic-record-alert-content-cancel-title {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  margin-top: 18px;
  text-align: left;
}

.dynamic-record-alert-content-text {
  max-width: 324px;
  font-size: 14px;
  color: #000000;
  margin-top: 12px;
  text-align: left;
  word-wrap: break-word;
}

.dynamic-record-alert-confirm-button-div {
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 32px;
}

.dynamic-record-alert-confirm-buttons {
  width: 202px;
  display: flex;
  justify-content: space-between;
}

.dynamic-record-alert-confirm-buttons .alert-cancel-button,
.dynamic-record-alert-confirm-buttons .alert-delete-button {
  width: 96px;
  height: 30px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.dynamic-record-alert-confirm-buttons .alert-cancel-button {
  background-color: #e9e9e9;
  color: #475569;
}

.dynamic-record-alert-confirm-buttons .alert-delete-button {
  background-color: #e17375;
  color: #ffffff;
}

/* Confirmation popup css end */

/* DynamicDetailsPage Component Style start*/
.dynamic-details-body {
  width: 100%;
  height: 90vh;
  overflow-y: hidden;
  background-color: white;
}

.dynamic-details-detail-bar {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: #00000020 0px 1px 1px;
}

.dynamic-details-detail-sub-bar {
  width: 71.5%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.dynamic-details-detail-bar-content-box {
  width: auto;
  height: 18px;
  min-width: 130px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: start;
}

.dynamic-details-detail-bar-cancel-arrow-box {
  width: 18px;
  height: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dynamic-details-detail-bar-cancel-arrow-box img {
  width: 100%;
  height: 100%;
}

.dynamic-details-detail-bar-person-name {
  min-width: 103px;
  height: 100%;
  font-size: 16px;
  color: #101010;
  font-family: "puvi-regular";
}

.dynamic-details-content {
  width: 100%;
  height: 87vh;
  display: flex;
}

.dynamic-details-part1-body,
.dynamic-details-part3-body {
  width: 14.2%;
  height: 100%;
}

.dynamic-details-part1-sub-body {
  width: 100%;
  height: 100%;
  border-radius: 0px 25px 0px 0px;
  border: 1px solid #00000020;
  border-top: none;
}

.dynamic-details-part3-sub-body {
  width: 100%;
  height: 100%;
  border-radius: 25px 0px 0px 0px;
  border: 1px solid #00000020;
  border-top: none;
}

.dynamic-details-part2-body {
  width: 71.5%;
  height: 83vh;
}

.dynamic-details-part2-content1-body {
  width: 100%;
  height: 141px;
  margin-top: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dynamic-details-part2-content1-sub-body {
  width: 93%;
  height: 100%;
  display: flex;
  gap: 2%;
}

.dynamic-details-part2-content1-img-box {
  width: 109px;
  height: 109px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #0086ff;
}

.dynamic-details-part2-content1-img-box img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 50%;
}

.dynamic-details-part2-content1-details-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.dynamic-details-part2-content1-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}

.dynamic-details-part2-content1-details-title,
.dynamic-details-part2-content1-details-content {
  width: 120px;
  font-size: 14px;
  color: #000000;
  font-family: "puvi-regular";
  text-align: start;
  white-space: nowrap;
}

.dynamic-details-part2-content1-details-title {
  flex: 0 1 auto;
  color: #000000;
}

.dynamic-details-part2-content1-details-content {
  flex: 1 1 auto;
  color: #000000b2;
}
/* DynamicDetailsPage Component Style end*/

/* Layout CSS start */
.dynamic-layout {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.dynamic-layout-header {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  background-color: white;
}

.dynamic-layout-header-box {
  width: 100%;
  height: 100%;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dynamic-layout-header-left {
  /* width: 20%; */
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.dynamic-layout-header-text {
  white-space: nowrap;
}

.dynamic-page-back-arrow {
  width: 30px;
  height: 30px;
  z-index: 1;
  cursor: pointer;
  margin-right: 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e9e9e9;
}

.dynamic-page-back-arrow img {
  width: 16px;
  height: 16px;
}

.dynamic-page-header-text {
  font-size: 16px;
  color: #000000;
  font-family: "puvi-Regular";
}

.dynamic-layout-header-right {
  height: 100%;
  display: flex;
  gap: 14px;
  justify-content: space-around;
  align-items: center;
  z-index: 1;
}

.dynamic-layout-header-btn-body {
  height: 28px;
  display: flex;
  gap: 21px;
  align-items: center;
  justify-content: end;
}

.dynamic-layout-button1 {
  width: 96px;
  height: 30px;
  border-radius: 4px;
  border: none;
  background-color: #3d65fa;
  color: #fff;
  cursor: pointer;
}

.dynamic-activate-btn {
  width: 96px;
  height: 30px;
  border-radius: 4px;
  border: none;
  background-color: #3d65fa;
  color: #fff;
  cursor: pointer;
}

.dynamic-deactivate-btn {
  width: 96px;
  height: 30px;
  border-radius: 4px;
  border: none;
  background-color: #e17375;
  color: #fff;
  cursor: pointer;
}

.dynamic-layout-button2 {
  width: 97px;
  height: 30px;
  border-radius: 4px;
  border: none;
  color: #475569;
  cursor: pointer;
  background-color: #e9e9e9;
}

.details-page-header-icon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.dynamic-layout-integration-btn button {
  background-color: #434ee1;
  color: #fff;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 4px;
  border: none;
}

.contact-chat-button-body {
  margin: 0px 10px;
}

.contact-chat-open-button {
  background-color: #434ee1;
  color: white;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 4px;
  border: none;
}

.contact-chat-enable-button {
  background-color: #4caf50;
  color: white;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 4px;
  border: none;
}

.dynamic-layout-integration-btn button a {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.next-prev-selection-body {
  width: auto;
  height: 100%;
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: space-between;
}

.next-prev-selection-sub-body {
  width: auto;
  height: 28px;
  border-radius: 4px;
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 0px 5px;
  justify-content: space-between;
}

.details-selection-body {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.1s;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
}

.details-selection-body:hover {
  background-color: #e6ebff;
}

.details-selection-img-body {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.details-selection-img-body img {
  width: 90%;
  height: 90%;
  border-radius: 50%;
}

.selected-value-count {
  width: 35px;
  font-size: 12px;
  color: #0086ff;
  text-align: center;
}

.dynamic-layout-main {
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  border-top: 1px solid #e9e9e9;
}

.dynamic-layout-main-center {
  width: 100%;
  height: 100%;
  padding: 0px 60px;
  overflow-y: auto;
}

.dynamic-layout-main-center::-webkit-scrollbar {
  display: none;
}

/* Layout CSS end */

/* Detail container start */
.details-channel-div {
  cursor: pointer;
  color: #0000ee;
  font-family: "puvi-medium";
}

.details-channel-popup-div {
  cursor: pointer;
  color: #000000b2;
  text-decoration: none;
}

.dynamic-details-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.dynamic-details-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-top: 29px;
  overflow-y: scroll;
}

.dynamic-details-box::-webkit-scrollbar {
  display: none;
}

.campaign-warning-box {
  display: flex;
  align-items: center;
  align-self: center;
}

.campaign-warning-box .link-button {
  color: #3d65fa;
  cursor: pointer;
  text-decoration: underline;
  font-family: "puvi-medium";
  font-size: 14px;
  padding: 10px;
}

.dynamic-detail-section {
  margin-bottom: 20px;
}

.dynamic-detail-section p {
  font-size: 16px;
  font-family: "puvi-semi-bold";
  margin-bottom: 10px;
}

.dynamic-detail-content-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.dynamic-detail {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}

.dynamic-detail-title,
.dynamic-detail-content {
  width: 120px;
  font-size: 14px;
  color: #000000;
  font-family: "puvi-regular";
  text-align: start;
  white-space: nowrap;
}

.dynamic-detail-title {
  flex: 0 1 auto;
  color: #000000;
  font-size: 14px;
  font-family: "puvi-semi-bold";
}

.dynamic-detail-content {
  margin-top: 10px;
  flex: 1 1 auto;
  color: #475569;
  font-family: "puvi-medium";
}

.details-extra-components {
  width: 100%;
  /* height: auto; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.add-members-main-body {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000B2;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 3;
}

.add-members-main-sub-body{
  width: 500px;
  padding:  50px;
  padding-top: 40px;
  padding-bottom: 80px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-members-title-2 {
  width: 100%;
  font-size: 16px;
  text-align: start;
  font-family: "puvi-semi-bold";
}

.add-members-drop-down-box {
  margin-top: 20px;
  padding-bottom: 50px;
}

.details-list-header {
  width: 100%;
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details-list-header button {
  padding: 0px 26px;
  height: 30px;
  border-radius: 4px;
  border: none;
  background-color: #3d65fa;
  color: #fff;
  cursor: pointer;
  text-align: center;
  align-content: center;
}

.details-list-header p {
  font-family: "puvi-semi-bold";
  font-size: 16px;
}

.related-records {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  background-color: white;
  margin-bottom: 10px;
}

.related-records thead th:nth-child(2) {
  text-indent: 10px;
}
/* Detail container end */

/* Details page start */
.detail-edit-btn {
  height: 20px;
  border: none;
  cursor: pointer;
  color: #3d65fa;
  border-radius: 4px;
  font-size: 14px;
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.detail-edit-btn1 {
  color: #3d65fa;
}

.detail-edit-btn2 {
  color: #e17375;
}
/* Details page end */

/* Form Component start */
.dynamic-form-component {
  width: 100%;
  height: 100%;
  display: flex;
}

.dynamic-form-section-right {
  width: 100%;
}

.dynamic-form-sections-container {
  width: 100%;
  margin-top: 30px;
}

.form-data-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 28px;
  margin-top: 24px;
}
/* Form Component end */

/* Phone Filed Component start  */
.phone-field-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  margin-top: 14px;
  font-size: 14px;
  border-radius: 10px;
  background-color: #ffffff;
}

.phone-field-container input {
  width: 83%;
  height: 44px;
  margin: 0;
  border: none;
  border: 1px solid #e9e9e9;
  background-color: white;
  border-radius: 0px 10px 10px 0px;
  z-index: 2;
}

.dynamic-phone-drop-down-input {
  width: 99% !important;
  border-radius: 10px !important;
}

.phone-field-container .phone-no-code-dropdown-arrow {
  width: 22px;
  height: 22px;
}
/* Phone Filed Component end  */

/* CountryDropDown Component Start  */
.country-field-container {
  width: 100%;
  height: 44px;
  z-index: 4;
  cursor: pointer;
  font-size: 14px;
  margin-top: 14px;
  padding: 0px 10px;
  position: relative;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
}

.country-field-dd-arrow-img-body {
  width: 24px;
  height: 24px;
}

.country-field-dd-arrow-img {
  width: 100%;
  height: 100%;
}

.country-code-dropdown {
  position: absolute;
  width: 100%;
  top: 60px;
  padding-top: 10px;
  overflow-y: scroll;
  background-color: #fff;
  border: 1px solid #eaeaec;
  border-radius: 4px;
  z-index: 3;
  border-radius: 0px 0px 10px 10px;
}

.country-code-dropdown::-webkit-scrollbar {
  display: none;
}
/* CountryDropDown Component end  */

/* DropDown (Country) Common Class Start */
.country-code-container-search {
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 3px;
  justify-content: center;
  background-color: white;
}

.country-code-sub-container-search {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.country-code-sub-container-search input {
  width: 99%;
  height: 44px;
  position: relative;
  border-radius: 10px;
  margin-top: 10px;
  border: 1px solid #e9e9e9;
  background-color: white;
}

.country-list-par-box {
  width: 100%;
  max-height: 150px;
}

.empty-message-div {
  width: 100%;
  height: 100%;
  color: #252525;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.country-code-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 39px;
  font-size: 14px;
  padding: 0px 10px;
}

.country-field-dd-value {
  width: 90%;
  overflow-x: auto;
  white-space: nowrap;
}

.country-code-container .multi-select-check-box {
  width: 15px;
  height: 15px;
}

.country-code-container:hover {
  background-color: #e6ebff;
}

.country-code {
  display: flex;
  width: 90%;
}

.country-code div {
  margin-left: 10px;
}

.country-code .name,
.country-code .code {
  font-size: 14px;
  color: #475569;
}

.country-code .flag {
  font-size: 14px;
}

.selected-tick {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* DropDown (Country) Common Class End */

/*Lookup Component start  */
.autocomplete-input-body {
  width: 100%;
  position: relative;
}

.autocomplete-input-body input {
  z-index: 4;
  position: relative;
}

.lookup-icon-box {
  width: 10%;
  height: 50%;
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
}

.lookup-icon-box img {
  width: 75%;
  height: 75%;
}

.autocomplete-dropdown-body {
  width: 100%;
  min-height: 34px;
  max-height: 150px;
  padding-top: 5px;
  position: absolute;
  top: 70px;
  left: 0px;
  z-index: 3;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #e9e9e9;
  border-radius: 0px 0px 10px 10px;
}

.autocomplete-dropdown-body::-webkit-scrollbar {
  display: none;
}

.autocomplete-dropdown-item {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-around;
}

.autocomplete-dropdown-item:hover {
  background-color: #e6ebff;
}

.autocomplete-dropdown-item-name {
  width: 100%;
  height: 15px;
  font-size: 14px;
  text-indent: 8px;
  color: #000000bf;
  align-content: center;
  font-family: "puvi-medium";
}

.autocomplete-dropdown-item-number {
  width: 100%;
  height: 15px;
  align-content: center;
  text-indent: 8px;
  font-size: 12px;
  color: #00000059;
}

.no-item-box {
  width: 100%;
  height: 150px;
  position: absolute;
  top: 70px;
  left: 0px;
  z-index: 3;
  text-align: center;
  align-content: center;
  background-color: white;
  border: 1px solid #e9e9e9;
  border-radius: 0px 0px 10px 10px;
}

/*Lookup Component end  */

.dynamic-form-component-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: pink; */
}

.dynamic-form-sections-container span {
  color: #000000;
  font-size: 14px;
  font-family: "puvi-medium";
  text-transform: uppercase;
}

.dynamic-form-sections-container p {
  color: red;
  font-size: 12px;
  /* margin-bottom: 24px; */
  font-family: "puvi-medium";
  /* text-transform: uppercase; */
  /* border: 1px solid grey; */
}
/* Form Component end */

/* Conversation container start */
.conversations-container {
  width: 100%;
  height: calc(100% - 123px);
  display: flex;
  flex-direction: column;
  margin-top: 33px;
}

.conversation-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.conversation-name-box {
  display: flex;
  gap: 8px;
  align-items: center;
}

.conversation-reload-btn {
  width: 14px;
  height: 14px;
  border: none;
  background-color: white;
  cursor: pointer;
}

.conversation-reload-btn img {
  width: 14px;
  height: 14px;
  color: pointer;
}

.conversation-name {
  color: #000;
  font-size: 14px;
  line-height: 16.06px;
  font-family: "puvi-semi-bold";
  text-transform: uppercase;
}

.new-conversation-button {
  background-color: white;
  color: #3d65fa;
  border: none;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  gap: 2px;
  align-items: center;
  font-family: "puvi-semi-bold";
}
.new-conversation-button img {
  width: 14px;
  height: 14px;
}

.conversation-main-body {
  width: 100%;
  overflow-y: auto;
  margin-top: 5px;
}

.conversation-main-body::-webkit-scrollbar {
  display: none;
}

/*.conversation-main-body::-webkit-scrollbar-track {
  background-color: white;
}

.conversation-main-body::-webkit-scrollbar-thumb {
  background-color: #d8d2c2;
  border-radius: 2px;
} */

.conversations-container .conversation {
  width: 100%;
  height: 63px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid #e9e9e9;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 3px 5px;
}

.conversation-content-box {
  width: 90%;
  height: 33px;
  /* background-color: pink; */
}

.conversation-title {
  width: 100%;
  height: 12px;
  color: #000000;
  font-size: 12px;
  font-family: "puvi-medium";
}

.conversation-content-sub-box {
  width: 100%;
  height: 15px;
  margin-top: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.conversation-content-name {
  font-size: 12px;
  color: #475569;
  font-family: "puvi-medium";
}

.conversation-content-state {
  font-size: 12px;
  font-family: "puvi-medium";
}

.conversation-details {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.conversation-details p {
  font-size: 10px;
  font-family: "puvi-regular";
  color: #787b7e;
}

.conversation-details p span {
  color: #000;
}

.conversation-actions {
  width: 20%;
  height: 100%;
  position: absolute;
  top: 0;
  right: -25%;
  transition: 0.3s;
  background-color: rgba(255, 255, 255, 0.8);
  border-left: 1px solid #e9e9e9;
}

.conversations-container .conversation:hover .conversation-actions {
  right: 0;
  /* transform: translateX(0); */
}

.conversation-edit-icon,
.conversation-delete-icon {
  cursor: pointer;
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.conversation-delete-icon img {
  width: 60%;
  height: 60%;
}

.conversation-edit-icon:hover,
.conversation-delete-icon:hover {
  background-color: #e6ebff;
}

.conversation-body-scroll-loading {
  padding: 10px 0px;
}

.no-conversations {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #89959c;
  font-size: 12px;
}

.details-component-popup-screen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000b2;
}

.details-component-popup {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 80%;
  height: 80vh;
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
}

.new-tab-button {
  background-color: #0086ff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
}

.detail-content {
  width: 100%;
  height: 100%;
}

.details-component-popup .detail-content .detail-box * {
  pointer-events: none;
  user-select: text;
}

.detail-popup-header {
  width: 100%;
  height: 6%;
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.detail-popup-header p {
  font-size: 16px;
  font-family: "puvi-medium";
  margin-left: 20px;
}

.details-popup-close-button {
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;
  background-color: white;
}

.details-popup-close-button img {
  width: 20px;
  height: 20px;
}

.detail-box {
  width: 100%;
  height: 84%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.detail-popup-footer {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-component-popup {
  width: 70%;
  max-width: 956px;
  height: 90vh;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
}

.form-popup-content {
  width: 100%;
  height: 100%;
}

.convo-info-alert-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.convo-info-alert-sub-box {
  color: 202224;
  font-size: 12px;
  padding: 14px 20px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background: #f2fcff;
  border-radius: 12px;
  border: 1px solid #73bbe1;
}

.convo-info-alert-sub-box img {
  width: 24px;
  height: 24px;
}

.convo-component-popup .form-popup-box {
  height: calc(100% - 114px);
}

.form-popup-header {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.form-popup-header p {
  font-size: 16px;
  font-family: "puvi-medium";
}

.form-action-buttons {
  width: auto;
  height: 30px;
  display: flex;
  gap: 9px;
  align-items: center;
  justify-content: space-around;
}

.form-action-buttons button {
  width: 97px;
  height: 30px;
  cursor: pointer;
  border-radius: 4px;
  border: none;
}

.form-popup-submit-button {
  color: #fff;
  background-color: #3d65fa;
}

.form-popup-close-button {
  color: #475569;
  background-color: #e9e9e9;
}

.form-popup-box {
  width: 100%;
  height: calc(100% - 60px);
  overflow-y: auto;
  margin-bottom: 10px;
}

.form-popup-box::-webkit-scrollbar {
  display: none;
}
/* Conversation container end */

/* Campaign stats start */

.campaign-warning-container {
  align-self: center;
  padding: 5px;
  color: #474747;
  border: 1px solid #e17375;
  background-color: #fff2f2;
  border-radius: 6px;
  border-radius: 12px;
  font-family: "puvi-medium";
  font-size: 14px;
  padding: 10px;
}

.campaign-stats-container {
  padding: 20px 0px;
  width: 100%;
}

.campaign-stats-container h3 {
  font-size: 16px;
  font-family: "puvi-semi-bold";
  margin-bottom: 20px;
}

/* Stats Table */
.stats-table {
  border: 1px solid #e9e9e9;
  margin-bottom: 20px;
  border-radius: 10px;
}

.stats-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 64px;
  max-height: 64px;
  white-space: nowrap;
}

.stats-row .clickable {
  cursor: pointer;
}

.stats-item.active {
  background-color: #3d65fa;
  color: #fff;
  border-radius: 10px;
}

.stats-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 16%;
  padding: 8px 0px;
  height: 64px;
  gap: 8px;
}

.stats-item span {
  font-size: 16px;
  font-family: "puvi-regular";
}

.stats-item .campaign-stats-count {
  font-size: 20px;
  font-family: "puvi-semi-bold";
}

.campaign-stats-table {
  border: 2px solid #e9e9e9;
  max-height: 300px;
  overflow-y: auto;
  border-radius: 10px;
  height: 300px;
  overflow-x: hidden;
}

.campaign-stats-table::-webkit-scrollbar {
  display: none;
}

.campaign-stats-table table {
  width: 100%;
  border-collapse: collapse;
}

.campaign-stats-header {
  background-color: #e6ebff;
  height: 56px;
}

.campaign-stats-header th:first-child {
  padding-left: 22px;
}

.campaign-stats-header th {
  padding: 10px;
  text-align: left;
  font-family: "puvi-regular";
  font-size: 14px;
}

.campaign-stats-row {
  border-bottom: 1px solid #ddd;
  height: 60px;
  font-family: "puvi-regular";
  font-size: 14px;
  color: #475569;
  position: relative;
}

.campaign-stats-row.loading {
  height: 180px;
}

.campaign-stats-row td:first-child {
  padding-left: 22px;
}

.campaign-stats-column {
  padding: 8px;
  font-size: 14px;
}

.campaign-stats-action {
  color: #0000ee;
  cursor: pointer;
  padding: 8px;
  font-size: 14px;
  font-family: "puvi-medium";
}

.campaign-stats-action.chat-link {
  visibility: hidden;
  position: absolute;
  top: 10px;
  right: -100px;
  transition: right 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.campaign-stats-row:hover .campaign-stats-action.chat-link {
  visibility: visible;
  right: 10px;
}

.campaign-stats-action.chat-link {
  visibility: hidden;
}

.campaign-stats-row:hover .campaign-stats-action.chat-link {
  visibility: visible;
}

.campaign-messages-stats-loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.campaign-stats-pagination-container {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 10px;
}

.no-data {
  padding: 15px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Campaign stats end */

/* campaign error banner start */

.campaign-failure-banner {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #474747;
  border: 1px solid #e17375;
  background-color: #fff2f2;
  border-radius: 12px;
  font-family: "puvi-medium";
  font-size: 14px;
  padding: 10px;
  align-self: center;
}

.campaign-failure-banner div img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

/* campaign error banner end */

/* campaign buttons start */

.campaign-button-container {
  margin: 0px 10px;
}

.run-campaign-btn {
  background-color: #434ee1;
  color: white;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 4px;
  border: none;
}

.run-campaign-btn.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.stop-campaign-btn {
  background-color: #e14343;
  color: white;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 4px;
  border: none;
}

/* campaign buttons end */

/* campaign template preview start */

.campaign-template-preview {
  width: 30%;
  height: 100%;
}

.campaign-template-preview-container {
  padding: 5px 20px;
  border-radius: 8px;
}

.campaign-runtime-params-container {
  margin-top: 20px;
}

.campaign-runtime-params-container p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}

.campaign-runtime-params-container div {
  margin-bottom: 15px;
}

.campaign-runtime-params-container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.campaign-runtime-params-container .edit-button {
  width: 88%;
  color: #434ee1;
  text-decoration: underline;
  font-family: "puvi-medium";
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
  padding: 5px 8px;
  border: none;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* campaign template preview end */

/* campaign popup form start */

.campaign-popup-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 3;
}

.campaign-popup-form {
  background-color: #fff;
  width: 60%;
  height: 70%;
  padding: 20px;
  border-radius: 12px;
  overflow-y: auto;
}

.campaign-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px;
  border-bottom: 1px solid #ccc;
}

.campaign-popup-header p {
  font-size: 16px;
  font-family: "puvi-medium";
}

.campaign-popup-header-buttons {
  display: flex;
  gap: 9px;
  align-items: center;

}

.campaign-popup-header-buttons .save-button {
  width: 96px;
  height: 30px;
  border-radius: 4px;
  border: none;
  background-color: #3d65fa;
  color: #fff;
  cursor: pointer;
}

.campaign-popup-header-buttons .cancel-button {
  width: 97px;
  height: 30px;
  border-radius: 4px;
  border: none;
  color: #475569;
  cursor: pointer;
  background-color: #e9e9e9;
}

.campaign-popup-form-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 28px;
  margin-top: 24px;
  overflow-y: auto;
  flex-grow: 1;
}

.campaign-file-upload-container {
  width: 25%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-left: 10px;
}

.campaign-file-upload-box {
  display: flex;
  align-items: center;
}

.campaign-popup-form-input-div {
  position: relative;
  width: 330px;
  box-sizing: border-box;
  margin-bottom: 22px;
}

.campaign-popup-form-input-div label {
  font-size: 16px;
  margin-bottom: 14px;
  color: #475569;
  font-family: "puvi-Medium";
}

.campaign-popup-form-input-div input {
  width: 100%;
  height: 44px;
  outline: none;
  margin-top: 14px;
  font-size: 14px;
  text-indent: 8px;
  border-radius: 10px;
  font-family: "puvi-Regular";
  border: 1px solid #e9e9e9;
  background-color: #ffffff;
}

.campaign-popup-form-label {
  font-size: 16px;
  margin-bottom: 14px;
  color: #475569;
  font-family: "puvi-Medium";
}

/* campaign popup form end */

/* Members Table */

.members-table-title-box{
  width: 100%;
  padding: 20px 0px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.members-table-title {
  font-size: 16px;
  font-family: "puvi-semi-bold";
}

.members-table-title-add-btn{
  padding: 0px 26px;
  height: 30px;
  border-radius: 4px;
  border: none;
  background-color: #3d65fa;
  color: #fff;
  cursor: pointer;
  text-align: center;
  align-content: center
}

.members-table-body {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow-y: auto;
  border: 1px solid #e9e9e9;
}

.members-table-body::-webkit-scrollbar {
  display: none;
}

.members-table {
  width: 100%;
  /* height: 100%; */
  border-collapse: collapse;
}

.members-table-thead {
  height: 56px;
  font-size: 14px;
  background-color: #e6ebff;
}

.members-table-tr {
  cursor: pointer;
  border-bottom: 1px solid #e9e9e9;
}

.members-table-td {
  height: 60px;
  text-align: start;
  text-indent: 10px;
  color: #475569;
  font-size: 14px;
}

.members-table-th-td {
  color: #000000;
}

.members-table-empty-meg-box {
  width: 100%;
  height: calc(100% - 60px);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Contact PopUp Form */
.contact-popup-form-body {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: #000000b2;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-form-popup {
  width: 70%;
  max-width: 956px;
  height: 90vh;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  overflow-y: auto;
}

.contact-popup-form-title-box {
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact-popup-title {
  font-size: 16px;
  color: #000000;
  font-family: "puvi-medium";
}

.contact-popup-form-btn-box {
  display: flex;
  gap: 9px;
  align-items: center;
  justify-content: center;
}

.contact-popup-form-btn-box button {
  width: 96px;
  height: 30px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.contact-popup-save-btn {
  color: white;
  background-color: #3d65fa;
}

.contact-popup-cancel-btn {
  color: #475569;
  background: #e9e9e9;
}
